import { CloudDownloadOutlined, FilePdfOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip } from 'antd';
import React from 'react'
import { BACKEND_URL } from '../../../../config/config';


const AdvancedAttendanceandRetentionReportSummaryTable = ({table_list,academic_year,semester,programme_intended,gender,identify,age,academic_year_list,gpa_range,programme_status,sem_list,programme_list}) => {


  const columns = [
    {
      title: "Student Name",
      render: (text, record) => {
        return (
          <>
            {record?.student?.first_name}{" "}
            {record?.student?.middle_name == "null"
              ? ""
              : record?.student?.middle_name}{" "}
            {record?.student?.last_name}
          </>
        );
      },
    },
    {
      title: "Student Id",
      render: (text, record) => {
        return <>{record?.student?.student_id}</>;
      },
    },
    {
      title: "Email",
      render: (text, record) => {
        return <>{record?.student?.primary_email}</>;
      },
    },
    {
      title: "course attendance records ",
      render: (text, record) => {
        return(
         <div style={{display:"flex", flexDirection:"column"}}>
          <p><span>Date:-</span> 12-01=2025</p>
          <p><span>Course:-</span>International Business(BA 207)</p>
          <p><span>Time:-</span>11:00 am - 12:00 pm</p>
         </div>
        )
      },
    },
    
    {
      title: "Program/Major",
      render: (text, record) => {
        return <>{record?.programme}</>;
      },
    },
    {
      title: "Enrollment Status",
      render: (text, record) => {
        return <>{record?.enrollment_status}</>;
      },
    },
    {
      title: "Retention Rates",
      render: (text, record) => {
        return <>{record?.retention_status}</>;
      },
    },
    
    {
      title: "Withdrawal Rates",
      render: (text, record) => {
        return <>{record?.withdraw_status}</>;
      },
    },
    
   
  ];



  return (
    <>
      <div
        style={{ display: "inline-block", float: "right", marginRight: "10px" }}
      >
        {/* CSV Download button  */}
        <Tooltip title="Download Attendance and Retention Report CSV">
          <Button
            type="primary"
            onClick={() => window.open(BACKEND_URL + '//' + JSON.parse(localStorage.getItem('sis_user_data')).token +'/'+ academic_year +'/'+ semester)} 
            ghost
            style={{ marginLeft: "5px" }}
          >
            <CloudDownloadOutlined />
          </Button>
        </Tooltip>
        {/* PDF Download button  */}
        <Tooltip title="Download Attendance and Retention Report PDF">
          <Button
            type="primary"
            onClick={() => window.open(BACKEND_URL + '//' + JSON.parse(localStorage.getItem('sis_user_data')).token +'/'+ academic_year +'/'+ semester)} 

            ghost
            style={{ marginLeft: "5px" }}
          >
            <FilePdfOutlined />
          </Button>
        </Tooltip>
      </div>
      <Table columns={columns} pagination={true} dataSource={table_list} />
    </>
  )
}

export default AdvancedAttendanceandRetentionReportSummaryTable;


