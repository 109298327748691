import { Checkbox, Input, notification, Pagination } from 'antd';
import Search from 'antd/es/transfer/search';
import React, { useEffect, useRef, useState } from 'react';
import { COURSES_LIST, SEARCH_COURSE, SEMESTERS_COURSES_UPDATE, SEMESTERS_COURSES_DELETE, CALENDAR_SCHEDULE_COURSES_SEARCH_LIST, CALENDAR_SCHEDULE_COURSES_SEARCH } from '../../../apis/apis';
import { useNavigate } from 'react-router-dom';
import { SearchOutlined, SmileOutlined, UserOutlined } from '@ant-design/icons';
import SearchCourseCheckbox from './SearchCourseCheckbox'
import SisLoader from '../../../widgets/loader';
const SearchCourse = (props) => {
    const { calendar_id, select_courses, sem_id,course_schedule_drawer} = props;
    const navigate = useNavigate();
    const [loader, set_loader] = useState(false);
    const [errors, set_errors] = useState([]);
    const [table_list, set_table_list] = useState([]);
    const [table_list_search, set_table_list_search] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [searchQuery, setSearchQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [last_page, set_last_page] = useState(1);
    const [total, set_total] = useState("");
    const [per_page, set_per_page] = useState("");
    const [debouncedQuery, setDebouncedQuery] = useState("");
    const [search_error, set_search_error] = useState("")
      const debounceTimeout = useRef(null);
      const errorTimeoutRef = useRef(null);

        // Search
  
        const debounce = (callback, delay) => {
            return (...args) => {
                clearTimeout(debounceTimeout.current);
                debounceTimeout.current = setTimeout(() => {
                    callback(...args);
                }, delay);
            };
        };
        
          const handleSearch = debounce((query) => {
            if(query != "")
            {
              SEARCH_LIST_API(query);
            }
            else{
              LIST_API(currentPage)
            }
          }, 800);
        
          const handleInputChange = (event) => {
            const value = event.target.value;
            setSearchQuery(value);
            if (errorTimeoutRef.current) {
              clearTimeout(errorTimeoutRef.current);
            }
            if (value === "") {
              set_search_error("");
              handleSearch(value);
              return;
            }
            if (value.length < 3) {
              errorTimeoutRef.current = setTimeout(() => {
              set_search_error("Please enter at least 3 characters.")
            }, 800);
              return;
            }
            set_search_error("");
            handleSearch(value);
          };

          const SEARCH_LIST_API = async (UserQuery) => {
            set_loader(true);
            const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
            const FORM_DATA = new FormData();
            FORM_DATA.append('token', USER_DATA.token);
            FORM_DATA.append('calendar_id', calendar_id);
            FORM_DATA.append('semester_id', sem_id);
            FORM_DATA.append("search", UserQuery);
            const API_RESPONSE = await CALENDAR_SCHEDULE_COURSES_SEARCH(FORM_DATA);
            if (API_RESPONSE?.data?.status) {
                set_table_list(API_RESPONSE?.data?.courses?.data);
                set_table_list_search(API_RESPONSE?.data?.courses?.data);
                setCurrentPage(API_RESPONSE?.data?.courses?.current_page);
                set_last_page(API_RESPONSE?.data?.courses?.last_page);
                set_total(API_RESPONSE?.data?.courses?.total);
                set_per_page(API_RESPONSE?.data?.courses?.per_page);
                set_loader(false);
            } else {
                set_loader(false);
            }
        }
        
    const check_permissions = (module_name, type) => {
        const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
        if(localStorgeData.role === 2074){
            return true;
        }
        var check_permissions = [];
        if (type === 'view') {
          check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.view);
      }
        if (type === 'create') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.create);
        }
        if (type === 'update') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.update);
        }
        if (type === 'status') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.status);
        }
        if (type === 'delete') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.delete);
        }
    
        if (check_permissions.length > 0) {
            return true;
        } else {
            return false;
        }
    }
    
    const LIST_API = async (UserSearchPage) => {
        set_loader(true);
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('calendar_id', calendar_id);
        FORM_DATA.append('semester_id', sem_id);
        FORM_DATA.append("page", UserSearchPage ? UserSearchPage : currentPage);
        const API_RESPONSE = await CALENDAR_SCHEDULE_COURSES_SEARCH_LIST(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            set_table_list(API_RESPONSE?.data?.courses?.data);
            set_table_list_search(API_RESPONSE?.data?.courses?.data);
            setCurrentPage(API_RESPONSE?.data?.courses?.current_page);
            set_last_page(API_RESPONSE?.data?.courses?.last_page);
            set_total(API_RESPONSE?.data?.courses?.total);
            set_per_page(API_RESPONSE?.data?.courses?.per_page);
            set_loader(false);
        } else {
            set_loader(false);
        }
    }


    //  useEffect(() => {
    //     set_table_list_search([])
    //       const timer = setTimeout(() => {
    //         setDebouncedQuery(searchQuery);
    //         LIST_API("",searchQuery)
    //       }, 500);
      
    //       return () => clearTimeout(timer); 
    //     }, [searchQuery,course_schedule_drawer]);


    useEffect(() => {
        setSearchQuery("");
        set_search_error("");
        LIST_API();
    }, [course_schedule_drawer]);

    const UPDATE_API = async (course_id, id) => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('calendar_id',  atob(calendar_id));
        FORM_DATA.append('semester_id', atob(sem_id));
        FORM_DATA.append('course_id', course_id);
        FORM_DATA.append('id', id);
        const API_RESPONSE = await SEMESTERS_COURSES_UPDATE(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            notification.open({
                message: 'Success!!',
                description: 'Course section added successfully!',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            navigate('/semester-courses/' + calendar_id + '/' + sem_id)
            set_loader(false)
        } else {
            set_errors(API_RESPONSE.data.errors);
        }
    }
    const DELETE_API = async (schedule_id) => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
    
        FORM_DATA.append('id', schedule_id);
        const API_RESPONSE = await SEMESTERS_COURSES_DELETE(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            notification.open({
                message: 'Success!!',
                description: 'Course section removed successfully!',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            set_loader(false)
           
            navigate('/semester-courses/' + calendar_id + '/' + sem_id)
        } else {
            set_errors(API_RESPONSE.data.errors);
        }
    }
    const set_sem_course = (e,item, selected_course_row) => {
        if(e.target.checked){
            UPDATE_API(item.id, 0)
          
        }else{
            var filter = selected_course_row.filter(fitem => (fitem.course_id === item.id));
            DELETE_API(filter[0].schedule_id)
          
        }
    }
    
    const pagination_changes  = (UserSearchPage) => {
        setCurrentPage(UserSearchPage);
        if(searchQuery == "")
            {
        LIST_API(UserSearchPage)
            }
            else
            {
              SEARCH_LIST_API(searchQuery)
            }
    };

    return (
        <>
         <h3>Search Courses</h3>
            <br/>
            <Input
            icon='search'
            placeholder='Search...'
            value={searchQuery}
            onChange={handleInputChange}
            // addonAfter={<SearchOutlined />}
            />
             {search_error && (
                  <>
                  <br/>
                  <span style={{ color: "red" }}>{search_error}</span>
                  </>
                )}
        {loader ? <SisLoader/>
        :
        <>
            <ul className='course-list-with-checkbox'>
                {table_list_search?.length > 0 ? <>
            <br/>
                    {table_list_search?.map((item, index) => (
                        <>
                            <li>
                                <div>
                                   
                                    <SearchCourseCheckbox 
                                    user_count={item.user_count} 
                                     calendar_id={calendar_id} 
                                     select_courses={select_courses} 
                                     item={item}
                                     sem_id={sem_id}
                                     LIST_API ={LIST_API}
                                     currentPage={currentPage}
                                     searchQuery={searchQuery}
                                     SEARCH_LIST_API={SEARCH_LIST_API}
                                      />
                                </div>
                                <div>
                                <b  style={{ backgroundColor:"#74b4f0", color:"#000" }}><UserOutlined /> {item.user_count}</b>
                                    <b>{item.code}</b>
                                    {/* <b>{item.user_count}</b> */}
                                </div>
                            </li>
                        </>
                    ))}
                <div style={{float:"right", marginTop:"20px"}}><Pagination onChange={pagination_changes} defaultCurrent={parseInt(currentPage)} total={parseInt(total)} pageSize={parseInt(per_page)} /></div>
       
                </> : <>
                    <li>Please add Courses</li>
                </>}
            </ul>
            
        </>
}
        </>
    );
};

export default SearchCourse;