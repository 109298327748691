import React, { useEffect, useRef, useState } from "react";
import {
  Radio,
  Table,
  Checkbox,
  Spin,
  Input,
  DatePicker,
  Select,
  notification,
  Button,
} from "antd";
import "./billing.css";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import dayjs from "dayjs";
import SisLoader from "../../widgets/loader";

import { LoadingOutlined, SmileOutlined } from "@ant-design/icons";

import GeneratePaymentTable from "./generatePaymentTable";

import {
  STUDENT_BILLING_PERIOD_DETAIL, // For View Data of Billing Period API
  VIEW_PAYMENT_PLAN_TABLE_API, // For View Data of Rows of Payment Plan
  ADD_BILLING_DETAIL,
  ADD_FINAL_PUBLISH_BILL_API,
  PAYMENT_PLAN_SELECT_OR_CHANGE_API,
  SIS_STORAGE,
} from "../../apis/apis";

const Genratebilling = () => {
  const dateFormat = "MM-DD-YYYY";
  const { acad_calendar_id, sem_id, id } = useParams();
  const navigate = useNavigate();
  const [all_permissions, set_all_permissions] = useState(false);
  const [permissions, set_permissions] = useState([]);
  const check_permissions = (module_name, type) => {
    const localStorgeData = JSON.parse(localStorage.getItem("sis_user_data"));
    if (localStorgeData.role === 2074) {
      return true;
    }
    var check_permissions = [];
    if (type === "create") {
      check_permissions = localStorgeData.role_permissions.permissions.filter(
        (permission) => permission.name === module_name && permission.create
      );
    }
    if (type === "update") {
      check_permissions = localStorgeData.role_permissions.permissions.filter(
        (permission) => permission.name === module_name && permission.update
      );
    }
    if (type === "status") {
      check_permissions = localStorgeData.role_permissions.permissions.filter(
        (permission) => permission.name === module_name && permission.status
      );
    }
    if (type === "delete") {
      check_permissions = localStorgeData.role_permissions.permissions.filter(
        (permission) => permission.name === module_name && permission.delete
      );
    }

    if (check_permissions.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const [loader, set_loader] = useState(false);
  const [page_loader, set_page_loader] = useState(true);
  const [PIP_Loader, set_PIP_Loader] = useState(false);

  const [student_final_billing, set_student_final_billing] = useState('');
  const [errors, set_errors] = useState([]);
  const [billing_detail, set_billing_detail] = useState("");
  const [course_fees, set_course_fees] = useState([]);
  const [list_payment_plans, set_list_payment_plans] = useState([]);
  const [semester_registration_fees, set_semester_registration_fees] = useState(
    []
  );
  const [semester_fees, set_semester_fees] = useState(1);
  const [late_registration_fees, set_late_registration_fees] = useState("");
  const [health_insurance_fees, set_health_insurance_fees] = useState("");
  const [late_charges, set_late_charges] = useState("");
  const [material_fees, set_material_fees] = useState("");
  const [test_fees, set_test_fees] = useState("");
  const [student_body_fees, set_student_body_fees] = useState("");
  const [id_card_fees, set_id_card_fees] = useState("");
  const [note_fees, set_note_fees] = useState("");
  const [publication_fees, set_publication_fees] = useState("");
  const [ecg_lab_fees, set_ecg_lab_fees] = useState("");
  const [tuition_recovery_fees, set_tuition_recovery_fees] = useState("");
  const [total_credit_fee, set_total_credit_fee] = useState("");
  const [library_fees, set_library_fees] = useState("");
  const [lab_fees, set_lab_fees] = useState("");
  const [upfrontPaymentDate, setUpfrontPaymentDate] = useState('');
  const [upfrontPaymentAmount, setUpfrontPaymentAmount] = useState('');
  const [selectedPaymentPlan, setSelectedPaymentPlan] = useState("");
  const [no_of_installments, set_no_of_installments] = useState("");
  const [amount_in_installments, set_amount_in_installments] = useState();
  const [amount_due_after_installment, set_amount_due_after_installment] =
    useState([]);
  const [total_amount_in_installments, set_total_amount_in_installments] =
    useState(0); // Assuming initial value is 0
  const [
    total_paid_amount_in_installments,
    set_total_paid_amount_in_installments,
  ] = useState(0); // initial 0
  const [total_bill_amount, set_total_bill_amount] = useState(); // Assuming initial value is 0
  const [payment_status, set_payment_status] = useState("not paid"); // Assuming initial value is empty string
  const [student_payment_plan_id, set_student_payment_plan_id] = useState(1); // for test purpose; set default to 1
  const [is_payment_plan, set_is_payment_plan] = useState("");
  const [
    payment_plans_dates_amount_array,
    set_payment_plans_dates_amount_array,
  ] = useState("");
  const [late_registration_fees_checked, set_late_registration_fees_checked] =
    useState(false);
  const [late_charges_checked, set_late_charges_checked] = useState(false);
  const [material_fees_checked, set_material_fees_checked] = useState(false);
  const [test_fees_checked, set_test_fees_checked] = useState(false);
  const [student_body_fees_checked, set_student_body_fees_checked] =
    useState(false);
  const [id_card_fees_checked, set_id_card_fees_checked] = useState(false);
  const [note_fees_checked, set_note_fees_checked] = useState("");
  const [publication_fees_checked, set_publication_fees_checked] =
    useState(false);
  const [ecg_lab_fees_checked, set_ecg_lab_fees_checked] = useState(false);
  const [tuition_recovery_fees_checked, set_tuition_recovery_fees_checked] =
    useState(false);
  const [library_fees_checked, set_library_fees_checked] = useState(false);
  const [lab_fees_checked, set_lab_fees_checked] = useState(false);

  const [health_insurance, set_health_insurance] = useState("");
  const[payment_plan_checkbox_disabled,set_payment_plan_checkbox_disabled] = useState("");

  const installmentIndices = Array.from(
    { length: no_of_installments },
    (_, index) => index
  );

  // const profilePicUrl = "https://picsum.photos/id/1/200/200";

  const VIEW_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("student_id", atob(id));
    FORM_DATA.append("semester_id", atob(sem_id));
    FORM_DATA.append("acad_calender_id", atob(acad_calendar_id));
    FORM_DATA.append("token", USER_DATA.token);

    const BILLING_API_RESPONSE = await STUDENT_BILLING_PERIOD_DETAIL(FORM_DATA);

    if (BILLING_API_RESPONSE?.data?.status) {
      set_payment_plans_dates_amount_array(BILLING_API_RESPONSE?.data?.payment_plans_dates);
      set_billing_detail(BILLING_API_RESPONSE?.data?.billing_period);
      set_course_fees(BILLING_API_RESPONSE?.data?.tuition_fees);
      const creditFeesArray = BILLING_API_RESPONSE?.data?.tuition_fees;
      const last = creditFeesArray[creditFeesArray?.length - 1]; // to get last value of array
      set_total_credit_fee(last?.total_tuition_fee); // to set total credit fee on load
      set_health_insurance(BILLING_API_RESPONSE?.data?.health_insurance);
      if (BILLING_API_RESPONSE?.data?.semester_registration_fees != null) {
        set_semester_registration_fees(
          BILLING_API_RESPONSE?.data?.semester_registration_fees
            ?.semester_registrations_fees
        );

        set_lab_fees(
          BILLING_API_RESPONSE?.data?.semester_registration_fees?.lab_fee
        );
        set_lab_fees_checked(
          BILLING_API_RESPONSE?.data?.semester_registration_fees?.lab_fee !=
            "null" &&
            BILLING_API_RESPONSE?.data?.semester_registration_fees?.lab_fee
            ? true
            : false
        );

        set_library_fees(
          BILLING_API_RESPONSE?.data?.semester_registration_fees?.library_fee
        );
        set_library_fees_checked(
          BILLING_API_RESPONSE?.data?.semester_registration_fees?.library_fee !=
            "null" &&
            BILLING_API_RESPONSE?.data?.semester_registration_fees?.library_fee
            ? true
            : false
        );

        set_tuition_recovery_fees(
          BILLING_API_RESPONSE?.data?.semester_registration_fees
            ?.tuition_recover_fee
        );
        set_tuition_recovery_fees_checked(
          BILLING_API_RESPONSE?.data?.semester_registration_fees
            ?.tuition_recover_fee != "null" &&
            BILLING_API_RESPONSE?.data?.semester_registration_fees
              ?.tuition_recover_fee
            ? true
            : false
        );

        set_ecg_lab_fees(
          BILLING_API_RESPONSE?.data?.semester_registration_fees?.ecg_lab_fee
        );
        set_ecg_lab_fees_checked(
          BILLING_API_RESPONSE?.data?.semester_registration_fees?.ecg_lab_fee !=
            "null" &&
            BILLING_API_RESPONSE?.data?.semester_registration_fees?.ecg_lab_fee
            ? true
            : false
        );

        set_publication_fees(
          BILLING_API_RESPONSE?.data?.semester_registration_fees
            ?.publication_fee
        );
        set_publication_fees_checked(
          BILLING_API_RESPONSE?.data?.semester_registration_fees
            ?.publication_fee != "null" &&
            BILLING_API_RESPONSE?.data?.semester_registration_fees
              ?.publication_fee
            ? true
            : false
        );

        set_note_fees(
          BILLING_API_RESPONSE?.data?.semester_registration_fees?.note_fee
        );
        set_note_fees_checked(
          BILLING_API_RESPONSE?.data?.semester_registration_fees?.note_fee !=
            "null" &&
            BILLING_API_RESPONSE?.data?.semester_registration_fees?.note_fee
            ? true
            : false
        );

        set_id_card_fees(
          BILLING_API_RESPONSE?.data?.semester_registration_fees?.id_card_fee
        );
        set_id_card_fees_checked(
          BILLING_API_RESPONSE?.data?.semester_registration_fees?.id_card_fee !=
            "null" &&
            BILLING_API_RESPONSE?.data?.semester_registration_fees?.id_card_fee
            ? true
            : false
        );

        set_student_body_fees(
          BILLING_API_RESPONSE?.data?.semester_registration_fees?.student_body
        );
        set_student_body_fees_checked(
          BILLING_API_RESPONSE?.data?.semester_registration_fees
            ?.student_body != "null" &&
            BILLING_API_RESPONSE?.data?.semester_registration_fees?.student_body
            ? true
            : false
        );

        set_test_fees(
          BILLING_API_RESPONSE?.data?.semester_registration_fees?.test_fee
        );
        set_test_fees_checked(
          BILLING_API_RESPONSE?.data?.semester_registration_fees?.test_fee !=
            "null" &&
            BILLING_API_RESPONSE?.data?.semester_registration_fees?.test_fee
            ? true
            : false
        );

        set_material_fees(
          BILLING_API_RESPONSE?.data?.semester_registration_fees?.material_fee
        );
        set_material_fees_checked(
          BILLING_API_RESPONSE?.data?.semester_registration_fees
            ?.material_fee != "null" &&
            BILLING_API_RESPONSE?.data?.semester_registration_fees?.material_fee
            ? true
            : false
        );

        set_late_charges(
          BILLING_API_RESPONSE?.data?.semester_registration_fees?.late_charges
        );
        set_late_charges_checked(
          BILLING_API_RESPONSE?.data?.semester_registration_fees
            ?.late_charges != "null" &&
            BILLING_API_RESPONSE?.data?.semester_registration_fees?.late_charges
            ? true
            : false
        );

        set_health_insurance_fees(
          BILLING_API_RESPONSE?.data?.semester_registration_fees
            ?.health_insurance
        );

        set_late_registration_fees(
          BILLING_API_RESPONSE?.data?.semester_registration_fees
            ?.late_registrations_fees
        );
        set_late_registration_fees_checked(
          BILLING_API_RESPONSE?.data?.semester_registration_fees
            ?.late_registrations_fees != "null" &&
            BILLING_API_RESPONSE?.data?.semester_registration_fees
              ?.late_registrations_fees
            ? true
            : false
        );
      } else {
        console.log("tuition fees total", last?.total_tuition_fee);
        set_semester_registration_fees(last?.total_tuition_fee ? "250" : "");
      }

      set_list_payment_plans(BILLING_API_RESPONSE?.data?.payment_plans);
      set_student_final_billing(BILLING_API_RESPONSE?.data?.student_final_bill != null && BILLING_API_RESPONSE?.data?.student_final_bill);
      if (BILLING_API_RESPONSE?.data?.student_final_bill) {
        console.log(
          "is pay plan api res",
          BILLING_API_RESPONSE?.data?.student_final_bill?.is_payment_plan
        );
        var isTrueSet =
          BILLING_API_RESPONSE?.data?.student_final_bill?.is_payment_plan ==
          "true";
        set_is_payment_plan( BILLING_API_RESPONSE?.data?.student_final_bill?.is_payment_plan ==
          "true" ? true : false);
        set_student_payment_plan_id(
          BILLING_API_RESPONSE?.data?.student_final_bill?.plan_id
        );
        setSelectedPaymentPlan(
          BILLING_API_RESPONSE?.data?.student_final_bill?.plan_id
        );

        const selectedPlanDetail =
          BILLING_API_RESPONSE?.data?.payment_plans.find(
            (plan) =>
              plan.id ===
              BILLING_API_RESPONSE?.data?.student_final_bill?.plan_id
          );

        console.log("in api payment plan full details", selectedPlanDetail);

        set_no_of_installments(selectedPlanDetail?.no_of_installments);
        setUpfrontPaymentAmount(
          BILLING_API_RESPONSE?.data?.student_final_bill?.upfront_amount
        );
        let total_sum_of_special_fees =
        (BILLING_API_RESPONSE?.data?.semester_registration_fees?.late_registrations_fees == "null" ? 0 : Number(BILLING_API_RESPONSE?.data?.semester_registration_fees?.late_registrations_fees))
       +(BILLING_API_RESPONSE?.data?.semester_registration_fees?.health_insurance == "null" ? 0 : Number(BILLING_API_RESPONSE?.data?.semester_registration_fees?.health_insurance))
       +(BILLING_API_RESPONSE?.data?.semester_registration_fees?.late_charges == "null" ? 0 : Number(BILLING_API_RESPONSE?.data?.semester_registration_fees?.late_charges))
       +(BILLING_API_RESPONSE?.data?.semester_registration_fees?.material_fee == "null" ? 0 : Number(BILLING_API_RESPONSE?.data?.semester_registration_fees?.material_fee))
       +(BILLING_API_RESPONSE?.data?.semester_registration_fees?.test_fee == "null" ? 0 : Number(BILLING_API_RESPONSE?.data?.semester_registration_fees?.test_fee))
       +(BILLING_API_RESPONSE?.data?.semester_registration_fees?.student_body == "null" ? 0 : Number(BILLING_API_RESPONSE?.data?.semester_registration_fees?.student_body))
       +(BILLING_API_RESPONSE?.data?.semester_registration_fees?.id_card_fee == "null" ? 0 : Number(BILLING_API_RESPONSE?.data?.semester_registration_fees?.id_card_fee))
       +(BILLING_API_RESPONSE?.data?.semester_registration_fees?.note_fee == "null" ? 0 : Number(BILLING_API_RESPONSE?.data?.semester_registration_fees?.note_fee))
       +(BILLING_API_RESPONSE?.data?.semester_registration_fees?.publication_fee == "null" ? 0 : Number(BILLING_API_RESPONSE?.data?.semester_registration_fees?.publication_fee))
       +(BILLING_API_RESPONSE?.data?.semester_registration_fees?.ecg_lab_fee == "null" ? 0 : Number(BILLING_API_RESPONSE?.data?.semester_registration_fees?.ecg_lab_fee))
       +(BILLING_API_RESPONSE?.data?.semester_registration_fees?.tuition_recover_fee == "null" ? 0 : Number(BILLING_API_RESPONSE?.data?.semester_registration_fees?.tuition_recover_fee))
       +(BILLING_API_RESPONSE?.data?.semester_registration_fees?.library_fee == "null" ? 0 : Number(BILLING_API_RESPONSE?.data?.semester_registration_fees?.library_fee))
       +(BILLING_API_RESPONSE?.data?.semester_registration_fees?.lab_fee == "null" ? 0 : Number(BILLING_API_RESPONSE?.data?.semester_registration_fees?.lab_fee))
       
        console.log("fees calculation", total_sum_of_special_fees)
        console.log("check true false",
          last?.total_tuition_fee +
          Number(BILLING_API_RESPONSE?.data?.semester_registration_fees?.semester_registrations_fees) +
          total_sum_of_special_fees
        )
       set_payment_plan_checkbox_disabled(Number(BILLING_API_RESPONSE?.data?.student_final_bill?.upfront_amount) == (
          last?.total_tuition_fee +
          Number(BILLING_API_RESPONSE?.data?.semester_registration_fees?.semester_registrations_fees) +
          total_sum_of_special_fees
        ) ? true : false);
        setUpfrontPaymentDate(
          BILLING_API_RESPONSE?.data?.student_final_bill?.upfront_payment_date
        );
        set_payment_status
        (
          BILLING_API_RESPONSE?.data?.student_final_bill?.payment_status);
      } else {
        set_is_payment_plan(false);
      }
    }

    set_page_loader(false);
  };

  const VIEW_PAYMENT_PLAN_TABLE = async (values) => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("student_id", atob(id));
    FORM_DATA.append("semester_id", atob(sem_id));
    FORM_DATA.append("acad_calender_id", atob(acad_calendar_id));
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("plan_id", values);

    const VIEW_PAYMENT_RESPONSE = await VIEW_PAYMENT_PLAN_TABLE_API(FORM_DATA);

    if (VIEW_PAYMENT_RESPONSE?.data?.status) {
      console.log("go", VIEW_PAYMENT_RESPONSE?.data?.payment_plans_dates[0]);

      set_payment_plans_dates_amount_array(
        VIEW_PAYMENT_RESPONSE?.data?.payment_plans_dates
      );
    }
    set_PIP_Loader(false);
  };

  useEffect(() => {
    VIEW_API();
    //  VIEW_PAYMENT_PLAN_TABLE();
  }, []);

  // Function to handle radio change for final payment status

  const handleRadioChange = (e) => {
    set_payment_status(e.target.value);
  };

  const handleCheckboxChange = () => {
    set_is_payment_plan(!is_payment_plan);
  };

  const columns = [
    {
      title: "Course Name",
      dataIndex: "courseName",
      key: "courseName",
      width: "20%",
      render: (text, record) => {
        return <> <span style={record?.course_status == 8 ? {textDecoration:"line-through"} : {textDecoration:"none"}}> {record.title}</span> </>;
      },
    },
    {
      title: "Course Code",
      dataIndex: "courseCode",
      key: "courseCode",
      width: "20%",
      render: (text, record) => {
        return <> <span style={record?.course_status == 8 ? {textDecoration:"line-through"} : {textDecoration:"none"}}> {record.code}</span> </>;
      },
    },
    {
      title: "Attempting Credits",
      dataIndex: "credit",
      key: "credit",
      width: "20%",
      render: (text, record) => {
        return <> <span style={record?.course_status == 8 ? {textDecoration:"line-through"} : {textDecoration:"none"}}> {record.given_credits}</span> </>;
      },
    },
    {
      title: "Cost per Unit",
      dataIndex: "unit",
      key: "unit",
      width: "20%",
      render: (text, record) => {
        return <> <span style={record?.course_status == 8 ? {textDecoration:"line-through"} : {textDecoration:"none"}}> {record.cost_per_unit}</span> </>;
      },
    },
    {
      title: "Total Cost",
      dataIndex: "totalCost",
      key: "totalCost",
      width: "20%",

      render: (text, record) => {
        return <> <span style={record?.course_status == 8 ? {textDecoration:"line-through"} : {textDecoration:"none"}}> {record.total_cost} </span><b style={{color:"red", fontWeight:"bold"}}>{record?.course_status == 8 && 'Drop'}</b> </>;
      },
    },
  ];

  function calculate_total_tuition_fees(course_fees) {
    let total = 0;

    // Iterate through each course fee and add it to the total
    for (let i = 0; i < course_fees.length; i++) {
      total += course_fees[i]?.total_cost;
    }

    return total;
  }

  const [installments, setInstallments] = useState({});

  const handleInstallmentUpdate = (index, amount, paid_status) => {
    console.log(
      "352 Parent Component Index n Amount",
      index,
      amount,
      paid_status
    );

    if (paid_status != 1 || paid_status != "1") {
      amount = 0;
    }

    setInstallments((prevInstallments) => {
      return { ...prevInstallments, [index]: amount };
    });
  };

  const totalInstallmentAmount = Object.values(installments).reduce(
    (total, amount) => total + parseInt(amount),
    0
  );

  const updateAmounts = (e, amountCheck) => {
    set_payment_plan_checkbox_disabled(e.target.value <= (amountCheck - 1) ? false : true);
 
   if(e.target.value <= amountCheck)
    {
      setUpfrontPaymentAmount(e.target.value);
    var $total_bill = isNaN(Number(total_credit_fee) + Number(sum_special_fees))
      ? 0
      : Number(total_credit_fee) + Number(sum_special_fees);
    var $total_amount_installments = isNaN(
      Number(total_credit_fee) +
      Number(sum_special_fees) -
      Number(e.target.value)
    )
      ? 0
      : Number(total_credit_fee) +
      Number(sum_special_fees) -
      Number(e.target.value);
    console.log("total bill", $total_bill);
    set_total_amount_in_installments($total_amount_installments);
    set_total_bill_amount($total_bill);
    }
  };

  const onPaymentPlanChange = async (value) => {
    set_PIP_Loader(true)
    set_payment_plans_dates_amount_array("")
    const selPlan = value;
    const selectedPlan = list_payment_plans.find((plan) => plan.id === selPlan);
    setSelectedPaymentPlan(selPlan);
    set_no_of_installments(Number(selectedPlan?.no_of_installments));

    var $total_in_installments = isNaN(
      Number(total_credit_fee) +
      Number(semester_registration_fees) +
      Number(sum_special_fees) -
      Number(upfrontPaymentAmount)
    )
      ? 0
      : Number(total_credit_fee) +
      Number(semester_registration_fees) +
      Number(sum_special_fees) -
      Number(upfrontPaymentAmount);

    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("student_id", atob(id));
    FORM_DATA.append("semester_id", atob(sem_id));
    FORM_DATA.append("acad_calender_id", atob(acad_calendar_id));
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("user_id", USER_DATA.id);
    FORM_DATA.append(
      "total_amount_payable_in_installments",
      $total_in_installments
    );
    FORM_DATA.append("plan_id", selPlan);
    const API_RESPONSE_PLAN_SELECT_OR_CHANGE =
      await PAYMENT_PLAN_SELECT_OR_CHANGE_API(FORM_DATA);
      if(API_RESPONSE_PLAN_SELECT_OR_CHANGE?.data?.status)
      {
        VIEW_PAYMENT_PLAN_TABLE(value);
      }
  };

  // useEffect(() => {
  //   VIEW_PAYMENT_PLAN_TABLE();
  // }, [selectedPaymentPlan]);

  const manage_semester_fees = (e) => {
    set_semester_fees(e.target.value);

    if (e.target.value == 1) {
      set_semester_registration_fees("250");
    } else {
      set_semester_registration_fees("150");
    }
  };

  const Add_Update_Publish_Bill = async () => {
    set_loader(true);

    var $total_bill = isNaN(Number(total_credit_fee) + Number(sum_special_fees))
      ? 0
      : Number(total_credit_fee) + Number(sum_special_fees);
    var $total_amount_installments = isNaN(
      Number(total_credit_fee) +
      Number(sum_special_fees) -
      Number(upfrontPaymentAmount)
    )
      ? 0
      : Number(total_credit_fee) +
      Number(sum_special_fees) -
      Number(upfrontPaymentAmount);
    console.log("total bill", $total_bill);
    set_total_bill_amount($total_bill);

    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    const FORM_DATA1 = new FormData();

    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("student_id", atob(id));
    FORM_DATA.append("semester_id", atob(sem_id));
    FORM_DATA.append("acad_calender_id", atob(acad_calendar_id));

    if (semester_fees == 1) {
      FORM_DATA.append("semester_registrations_fees", "250");
      set_semester_registration_fees("250");
    } else {
      FORM_DATA.append("semester_registrations_fees", "150");
      set_semester_registration_fees("150");
    }

    const total_credit_fee_value = calculate_total_tuition_fees(course_fees);

    FORM_DATA.append("tuition_fee", total_credit_fee_value);
    FORM_DATA.append("late_registrations_fees", late_registration_fees);
    FORM_DATA.append("health_insurance", health_insurance_fees);
    FORM_DATA.append("material_fee", material_fees);
    FORM_DATA.append("test_fee", test_fees);
    FORM_DATA.append("late_charges", late_charges);
    FORM_DATA.append("student_body", student_body_fees);
    FORM_DATA.append("id_card_fee", id_card_fees);
    FORM_DATA.append("note_fee", note_fees);
    FORM_DATA.append("publication_fee", publication_fees);
    FORM_DATA.append("ecg_lab_fee", ecg_lab_fees);
    FORM_DATA.append("tuition_recover_fee", tuition_recovery_fees);
    FORM_DATA.append("library_fee", library_fees);
    FORM_DATA.append("lab_fee", lab_fees);

    const API_ADD_BILLING_RESPONSE = await ADD_BILLING_DETAIL(FORM_DATA);

    FORM_DATA1.append("token", USER_DATA.token);
    FORM_DATA1.append("student_id", atob(id));
    FORM_DATA1.append("semester_id", atob(sem_id));
    FORM_DATA1.append("acad_calender_id", atob(acad_calendar_id));
    FORM_DATA1.append("total_bill_amount", total_bill_amount);
    FORM_DATA1.append("upfront_amount", upfrontPaymentAmount);
    FORM_DATA1.append("upfront_payment_date", upfrontPaymentDate);
    FORM_DATA1.append("payment_status", payment_status);
    FORM_DATA1.append("is_payment_plan", is_payment_plan);
    FORM_DATA1.append("amount_in_installments", total_amount_in_installments);
    FORM_DATA1.append("plan_id", selectedPaymentPlan);

    const ADD_FINAL_PUBLISH_BILL_RESPONSE = await ADD_FINAL_PUBLISH_BILL_API(
      FORM_DATA1
    );

    if (ADD_FINAL_PUBLISH_BILL_RESPONSE?.data?.status) {
      var $message =
        "Student " +
        billing_detail?.first_name +
        "  for Semester  " +
        billing_detail?.title +
        " Registration Bill Updated";

      notification.open({
        message: "Success!!",
        description: $message,
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      
       navigate("/billing");
    } else {
      set_errors(ADD_FINAL_PUBLISH_BILL_RESPONSE?.data?.errors);
    }
    set_loader(false);
  };

  let sum_special_fees =
    (Number(health_insurance_fees) || 0) +
    (Number(late_registration_fees) || 0) +
    (Number(material_fees) || 0) +
    (Number(late_charges) || 0) +
    (Number(test_fees) || 0) +
    (Number(student_body_fees) || 0) +
    (Number(id_card_fees) || 0) +
    (Number(note_fees) || 0) +
    (Number(ecg_lab_fees) || 0) +
    (Number(tuition_recovery_fees) || 0) +
    (Number(publication_fees) || 0) +
    (Number(lab_fees) || 0) +
    (Number(library_fees) || 0);

  const late_reg_fees_change = (e) => {
    set_late_registration_fees_checked(e.target.checked);
    if (!e.target.checked) {
      set_late_registration_fees("");
    }
  };

  const late_charges_change = (e) => {
    set_late_charges_checked(e.target.checked);
    if (!e.target.checked) {
      set_late_charges("");
    }
  };
  const material_fees_change = (e) => {
    set_material_fees_checked(e.target.checked);
    if (!e.target.checked) {
      set_material_fees("");
    }
  };

  const test_fees_change = (e) => {
    set_test_fees_checked(e.target.checked);
    if (!e.target.checked) {
      set_test_fees("");
    }
  };

  const student_body_fees_change = (e) => {
    set_student_body_fees_checked(e.target.checked);
    if (!e.target.checked) {
      set_student_body_fees("");
    }
  };

  const id_card_fees_change = (e) => {
    set_id_card_fees_checked(e.target.checked);
    if (!e.target.checked) {
      set_id_card_fees("");
    }
  };

  const note_fees_change = (e) => {
    set_note_fees_checked(e.target.checked);
    if (!e.target.checked) {
      set_note_fees("");
    }
  };

  const publication_fees_change = (e) => {
    set_publication_fees_checked(e.target.checked);
    if (!e.target.checked) {
      set_publication_fees("");
    }
  };

  const tuition_recovery_fees_change = (e) => {
    set_tuition_recovery_fees_checked(e.target.checked);
    if (!e.target.checked) {
      set_tuition_recovery_fees("");
    }
  };

  const ecg_lab_fees_change = (e) => {
    set_ecg_lab_fees_checked(e.target.checked);
    if (!e.target.checked) {
      set_ecg_lab_fees("");
    }
  };

  const lab_fees_change = (e) => {
    set_lab_fees_checked(e.target.checked);
    if (!e.target.checked) {
      set_lab_fees("");
    }
  };

  const library_fees_change = (e) => {
    set_library_fees_checked(e.target.checked);
    if (!e.target.checked) {
      set_library_fees("");
    }
  };

  // const  onChange_late_registration_fees = (e) =>{ set_late_registration_fees_checked(e.target.checked)}



  return (
    <div className="common-form">
      {page_loader ? (
        <SisLoader />
      ) : (
        <>
          {errors?.try && (
            <>
              <span style={{ color: "red" }}>{errors?.try[0]}</span>
            </>
          )}
          {errors?.catch && (
            <>
              <span style={{ color: "red" }}>{errors?.catch[0]}</span>
            </>
          )}
          <div className="header-top" style={{ marginBottom:"15px" }}>
            <h2>Billing For Semester</h2>
            <h3 style={{ color: "#900000" }}>
              Semester - {billing_detail?.title}
            </h3>
          </div>
          <div className="row genrate-billing-row-main">
            <div className="col-8">
              <div className="row" style={{ marginBottom: "20px" }}>
                <div className="col-2">
                  {billing_detail?.image &&
                  <img
                   src={SIS_STORAGE + "/enrollments/" + billing_detail?.image}
                    style={{
                      width: "78px",
                      height: "78px",
                      borderRadius: "14px",
                    }}
                  />
                  }
                </div>
                <div className="col-10">
                  <h3 style={{ marginBottom: "10px" }}>
                    {billing_detail?.first_name}{" "}
                    {((billing_detail?.middle_name)==="null"|| (billing_detail?.middle_name) === null) ? "" : (billing_detail?.middle_name)}{" "}
                    {billing_detail?.last_name}{" "}
                  </h3>
                  <div className="row">
                    <div className="col-6">
                      <p>Application Number</p>
                      <h4>{billing_detail?.application_no}</h4>
                    </div>
                    <div className="col-3">
                      <p>Student ID</p>
                      <h4>{billing_detail?.enrollments_student_id}</h4>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row" style={{ marginBottom: "20px" }}>
                <div className="col-4">
                  <p>Primary Email</p>
                  <h4>{billing_detail?.primary_email}</h4>
                </div>
                <div className="col-4">
                  <p>Secondary Email</p>
                  <h4>{billing_detail?.secondary_email}</h4>
                </div>
                <div className="col-2">
                  <p>Phone Number</p>
                  <h4>{billing_detail?.phone}</h4>
                </div>
                <div className="col-2">
                  <p>Emergency Mobile</p>
                  <h4>{billing_detail?.emergency_contacts_phone}</h4>
                </div>
              </div>
              <h4 style={{ marginBottom: "10px" }}>Tuition Fees</h4>
              <Table
                style={{
                  border: " 1px solid rgba(225, 225, 225, 1)",
                  borderRadius: "5px",
                  margin: "relative",
                }}
                columns={columns}
                dataSource={course_fees}
                pagination={false}
              />

              <div className="row">
                <div className="col-12">
                  <div className="input-box">
                    <label className="Semester_Registration_fees">
                      Semester Registration fees{" "}
                    </label>
                    <h5>
                      <Radio.Group
                        onChange={(e) => manage_semester_fees(e)}
                        value={semester_fees}
                      >
                        <Radio value={1}>
                          Semester Registration fees - $250 (non-refundable){" "}
                        </Radio>
                        <Radio value={2}>
                          Semester Registration fees - $150 (non-refundable)
                        </Radio>

                        <hr
                          style={{
                            border: "1px solid rgba(238, 238, 238, 1)",
                            marginTop: "24px",
                          }}
                        />
                      </Radio.Group>
                    </h5>
                  </div>
                </div>
              </div>


              <h4 style={{ marginBottom: "10px" }}> Special Fees</h4>
              <div className="row">
                <div className="col-7">

                  <Checkbox
                    onChange={(e) => late_reg_fees_change(e)}
                    checked={late_registration_fees_checked}
                  >
                    Late Registration fee (charged after the last add/drop
                    day)
                  </Checkbox>

                  <div
                    className="input-box"
                    style={{ width: "370px", marginTop: "6px" }}
                  >
                    <Input
                      disabled={!late_registration_fees_checked}
                      value={
                        late_registration_fees != "null" &&
                          late_registration_fees
                          ? late_registration_fees
                          : ""
                      }
                      placeholder="late_registration_fees"
                      id="late_registration_fees"
                      onChange={(e) =>
                        set_late_registration_fees(e.target.value)
                      }
                    />
                  </div>

                </div>
                <div className="col-5">
                  <Checkbox
                    onChange={(e) => {
                      late_charges_change(e);
                    }}
                    checked={late_charges_checked}
                  >
                    Late Charges
                  </Checkbox>
                  <div
                    className="input-box"
                    style={{ width: "180px", marginTop: "6px" }}
                  >
                    <Input
                      style={{ width: "100%" }}
                      disabled={!late_charges_checked}
                      value={
                        late_charges != "null" && late_charges
                          ? late_charges
                          : ""
                      }
                      placeholder="late_fees"
                      id="late_charges"
                      onChange={(e) => set_late_charges(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-3">
                  <Checkbox
                    onChange={(e) => {
                      material_fees_change(e);
                    }}
                    checked={material_fees_checked}
                  >
                    Material Fee
                  </Checkbox>
                  <div
                    className="input-box"
                    style={{ width: "180px", marginTop: "6px" }}
                  >
                    <Input
                      disabled={!material_fees_checked}
                      value={
                        material_fees != "null" && material_fees
                          ? material_fees
                          : ""
                      }
                      placeholder="material_fees"
                      id="material_fees"
                      onChange={(e) => set_material_fees(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-3">
                  <Checkbox
                    onChange={(e) => {
                      test_fees_change(e);
                    }}
                    checked={test_fees_checked}
                  >
                    Test Fee
                  </Checkbox>
                  <div
                    className="input-box"
                    style={{ width: "180px", marginTop: "6px" }}
                  >
                    <Input
                      disabled={!test_fees_checked}
                      value={
                        test_fees != "null" && test_fees ? test_fees : ""
                      }
                      placeholder="test_fees"
                      id="test_fees"
                      onChange={(e) => set_test_fees(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-3">
                  <Checkbox
                    onChange={(e) => {
                      student_body_fees_change(e);
                    }}
                    checked={student_body_fees_checked}
                  >
                    Student Body
                  </Checkbox>
                  <div
                    className="input-box"
                    style={{ width: "180px", marginTop: "6px" }}
                  >
                    <Input
                      disabled={!student_body_fees_checked}
                      value={
                        student_body_fees != "null" && student_body_fees
                          ? student_body_fees
                          : ""
                      }
                      placeholder="student_body_fees"
                      id="student_body_fees"
                      onChange={(e) =>
                        set_student_body_fees(e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-3">
                  <Checkbox
                    onChange={(e) => {
                      id_card_fees_change(e);
                    }}
                    checked={id_card_fees_checked}
                  >
                    I.D. card fee
                  </Checkbox>
                  <div
                    className="input-box"
                    style={{ width: "180px", marginTop: "6px" }}
                  >
                    <Input
                      disabled={!id_card_fees_checked}
                      value={
                        id_card_fees != "null" && id_card_fees
                          ? id_card_fees
                          : ""
                      }
                      placeholder="id_card_fees"
                      id="id_card_fees"
                      onChange={(e) => set_id_card_fees(e.target.value)}
                    />
                  </div>
                </div>
              </div>


              <div className="row">
                <div className="col-3">
                  <Checkbox
                    onChange={(e) => {
                      note_fees_change(e);
                    }}
                    checked={note_fees_checked}
                  >
                    Note Fee
                  </Checkbox>
                  <div
                    className="input-box"
                    style={{ width: "180px", marginTop: "6px" }}
                  >
                    <Input
                      disabled={!note_fees_checked}
                      value={
                        note_fees != "null" && note_fees ? note_fees : ""
                      }
                      placeholder="note_fees"
                      id="note_fees"
                      onChange={(e) => set_note_fees(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-3">
                  <Checkbox
                    onChange={(e) => {
                      publication_fees_change(e);
                    }}
                    checked={publication_fees_checked}
                  >
                    Publication Fee
                  </Checkbox>
                  <div
                    className="input-box"
                    style={{ width: "180px", marginTop: "6px" }}
                  >
                    <Input
                      disabled={!publication_fees_checked}
                      value={
                        publication_fees != "null" && publication_fees
                          ? publication_fees
                          : ""
                      }
                      placeholder="publication_fees"
                      id="publication_fees"
                      onChange={(e) =>
                        set_publication_fees(e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-3">
                  <Checkbox
                    onChange={(e) => {
                      ecg_lab_fees_change(e);
                    }}
                    checked={ecg_lab_fees_checked}
                  >
                    ECG lab Fee
                  </Checkbox>
                  <div
                    className="input-box"
                    style={{ width: "180px", marginTop: "6px" }}
                  >
                    <Input
                      disabled={!ecg_lab_fees_checked}
                      value={
                        ecg_lab_fees != "null" && ecg_lab_fees
                          ? ecg_lab_fees
                          : ""
                      }
                      placeholder="ecg_lab_fees"
                      id="ecg_lab_fees"
                      onChange={(e) => set_ecg_lab_fees(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-3">
                  <Checkbox
                    onChange={(e) => {
                      tuition_recovery_fees_change(e);
                    }}
                    checked={tuition_recovery_fees_checked}
                  >
                    Tution Recover Fee
                  </Checkbox>
                  <div
                    className="input-box"
                    style={{ width: "180px", marginTop: "6px" }}
                  >
                    <Input
                      disabled={!tuition_recovery_fees_checked}
                      value={
                        tuition_recovery_fees != "null" &&
                          tuition_recovery_fees
                          ? tuition_recovery_fees
                          : ""
                      }
                      placeholder="tuition_recovery_fees"
                      id="tuition_recovery_fees"
                      onChange={(e) =>
                        set_tuition_recovery_fees(e.target.value)
                      }
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-3">
                  <Checkbox
                    onChange={(e) => {
                      library_fees_change(e);
                    }}
                    checked={library_fees_checked}
                  >
                    Library Fee
                  </Checkbox>
                  <div
                    className="input-box"
                    style={{ width: "180px", marginTop: "6px" }}
                  >
                    <Input
                      disabled={!library_fees_checked}
                      value={
                        library_fees != "null" && library_fees
                          ? library_fees
                          : ""
                      }
                      placeholder="library_fees"
                      id="library_fees"
                      onChange={(e) => set_library_fees(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-3">
                  <Checkbox
                    onChange={(e) => {
                      lab_fees_change(e);
                    }}
                    checked={lab_fees_checked}
                  >
                    Lab Fee
                  </Checkbox>
                  <div
                    className="input-box"
                    style={{ width: "180px", marginTop: "6px" }}
                  >
                    <Input
                      disabled={!lab_fees_checked}
                      value={
                        lab_fees != "null" && lab_fees ? lab_fees : ""
                      }
                      placeholder="lab_fees"
                      id="lab_fees"
                      onChange={(e) => set_lab_fees(e.target.value)}
                    />
                  </div>
                </div>
              </div>


              <h3 style={{ marginTop: "17px", marginBottom: "15px" }}>
                {" "}
                * Fee Scheduling,including tution rates is effective current
                term.Term is a semester or a spring session.<br></br> ** non
                refundable after the first class sesssion or seventh day after
                the classes begin, whichever is later.<br></br> *** The fee is
                waived if the student later applies to lincoln university .{" "}
              </h3>

              <h3>Note :</h3>
              <h3>
                For Refund Policy, please refer to{" "}
                <a
                  href="link_to_catalog_page"
                  style={{ textDecoration: "underline" }}
                >
                  catalog
                </a>
                .
              </h3>



            </div>
            <div className="col-4">
              <div className="billing_detail">
                <div className="billing_font">Billing detail</div>
                <div className="fall">
                  <span>Billing period</span>
                  <span style={{ color: "#3B82F6" }}>
                    {billing_detail?.title}
                  </span>
                </div>
                <div className="Total_Due">
                  <div className="billing_row">
                    <span>Tuition Fees</span>
                    <span>{total_credit_fee}</span>
                  </div>
                  <div className="billing_row">
                    <span>Semester Registration Fees</span>
                    <span>{semester_registration_fees}</span>
                  </div>
                  <div className="billing_row">
                    <h4>Sub Total</h4>
                    <h4>
                      ${" "}
                      {isNaN(
                        Number(total_credit_fee) +
                        Number(semester_registration_fees)
                      )
                        ? 0
                        : Number(total_credit_fee) +
                        Number(semester_registration_fees)}
                    </h4>
                  </div>

                  <div style={{ color: "#8E8E8E" }} className="billing_row">
                    {" "}
                    Special Fees
                  </div>

                  {health_insurance_fees && !isNaN(health_insurance_fees) && (
                    <>
                      <div className="billing_row">
                        <span>Health Insurance </span>
                        <span>$ {health_insurance_fees} </span>
                      </div>
                    </>
                  )}
                  {late_registration_fees && !isNaN(late_registration_fees) && (
                    <>
                      <div className="billing_row">
                        <span>Late Registration Fees </span>
                        <span>$ {Number(late_registration_fees)} </span>
                      </div>
                    </>
                  )}

                  {material_fees && !isNaN(material_fees) && (
                    <>
                      <div className="billing_row">
                        <span>Material Fees </span>
                        <span>$ {Number(material_fees)} </span>
                      </div>
                    </>
                  )}

                  {late_charges && !isNaN(late_charges) && (
                    <>
                      <div className="billing_row">
                        <span>Late Charges </span>
                        <span>$ {Number(late_charges)} </span>
                      </div>
                    </>
                  )}

                  {test_fees && !isNaN(test_fees) && (
                    <>
                      <div className="billing_row">
                        <span>Test Fees </span>
                        <span>$ {Number(test_fees)} </span>
                      </div>
                    </>
                  )}

                  {student_body_fees && !isNaN(student_body_fees) && (
                    <>
                      <div className="billing_row">
                        <span>Student Body Fees </span>
                        <span>$ {Number(student_body_fees)} </span>
                      </div>
                    </>
                  )}

                  {note_fees && !isNaN(note_fees) && (
                    <>
                      <div className="billing_row">
                        <span>Note Fees </span>
                        <span>$ {Number(note_fees)} </span>
                      </div>
                    </>
                  )}

                  {tuition_recovery_fees && !isNaN(tuition_recovery_fees) && (
                    <>
                      <div className="billing_row">
                        <span>Tuition Recovery Fees </span>
                        <span>$ {parseInt(tuition_recovery_fees)} </span>
                      </div>
                    </>
                  )}

                  {publication_fees && !isNaN(publication_fees) && (
                    <>
                      <div className="billing_row">
                        <span>Publication Fees </span>
                        <span>$ {Number(publication_fees)} </span>
                      </div>
                    </>
                  )}

                  {lab_fees && !isNaN(lab_fees) && (
                    <>
                      <div className="billing_row">
                        <span>Lab Fees </span>
                        <span>$ {Number(lab_fees)} </span>
                      </div>
                    </>
                  )}

                  {library_fees && !isNaN(library_fees) && (
                    <>
                      <div className="billing_row">
                        <span>Library Fees </span>
                        <span>$ {Number(library_fees)} </span>
                      </div>
                    </>
                  )}

                  {id_card_fees && !isNaN(id_card_fees) && (
                    <>
                      <div className="billing_row">
                        <span>Id Card Fees </span>
                        <span>$ {Number(id_card_fees)} </span>
                      </div>
                    </>
                  )}

                  {ecg_lab_fees && !isNaN(ecg_lab_fees) && (
                    <>
                      <div className="billing_row">
                        <span>ECG lab Fee </span>
                        <span>$ {Number(ecg_lab_fees)} </span>
                      </div>
                    </>
                  )}

                  <div className="billing_row">
                    <h4>Total of Special Fees</h4>
                    <h4>$ {isNaN(sum_special_fees) ? 0 : sum_special_fees}</h4>
                  </div>

                  <div className="billing_row">
                    <h4>Total billing amount</h4>
                    <h4>
                      ${" "}
                      {isNaN(
                        Number(total_credit_fee) +
                        Number(semester_registration_fees) +
                        sum_special_fees
                      )
                        ? 0
                        : Number(total_credit_fee) +
                        Number(semester_registration_fees) +
                        sum_special_fees}
                    </h4>
                  </div>

                  <div className="billing_row">
                    <span>Upfront payment {student_final_billing?.upfront_amount_payment_status && <><span style={{ color:"green", fontWeight:"bold" }}>Paid</span></> }</span>
                  </div>
                {student_final_billing?.upfront_amount_payment_status ? <>
                  <div className="billing_row">
                    <DatePicker
                      format={dateFormat}
                      value={student_final_billing?.upfront_payment_date && dayjs(student_final_billing?.upfront_payment_date)}
                      disabled={true}
                      style={{ width: "100%" }}
                    />
                    {errors?.upfront_payment_date && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.upfront_payment_date[0]}
                        </span>
                      </>
                    )}
                  </div>

                  <div className="billing_row">
                    <Input
                     disabled={true}
                      value={student_final_billing?.upfront_amount}
                    />
                    {errors?.upfront_amount && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.upfront_amount[0]}
                        </span>
                      </>
                    )}
                  </div>
                </> : <>
                  <div className="billing_row">
                    <DatePicker
                      format={dateFormat}
                      value={upfrontPaymentDate && dayjs(upfrontPaymentDate)}
                      onChange={(date, dateString) =>
                        setUpfrontPaymentDate(dateString)
                      }
                      style={{ width: "100%" }}
                    />
                    {errors?.upfront_payment_date && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.upfront_payment_date[0]}
                        </span>
                      </>
                    )}
                  </div>

                  <div className="billing_row">
                    <Input
                      value={upfrontPaymentAmount > ( Number(total_credit_fee) +
                        Number(semester_registration_fees) +
                        sum_special_fees) ? ( Number(total_credit_fee) +
                        Number(semester_registration_fees) +
                        sum_special_fees) : upfrontPaymentAmount}
                      placeholder="upfrontPaymentAmount"
                      id="upfrontPaymentAmount"
                      onChange={(e) => updateAmounts(e, isNaN(
                        Number(total_credit_fee) +
                        Number(semester_registration_fees) +
                        sum_special_fees
                      )
                        ? 0
                        : Number(total_credit_fee) +
                        Number(semester_registration_fees) +
                        sum_special_fees) + 1}
                    />
                    {errors?.upfront_amount && (
                      <>
                        <span style={{ color: "red" }}>
                          {errors?.upfront_amount[0]}
                        </span>
                      </>
                    )}
                  </div>
                </>}
               

                  <div className="billing_row">
                    <h4>Remaining Dues at Semester Registration </h4>
                    <h4 style={{ color: "#DFA414" }}>
                      {" "}
                      ${" "}
                      {isNaN(
                        Number(total_credit_fee) +
                        Number(semester_registration_fees) +
                        sum_special_fees -
                        Number(upfrontPaymentAmount)
                      )
                        ? 0
                        : (Number(total_credit_fee) +
                        Number(semester_registration_fees) +
                        sum_special_fees -
                        Number(upfrontPaymentAmount)) < 0 ? 0 : (Number(total_credit_fee) +
                        Number(semester_registration_fees) +
                        sum_special_fees -
                        Number(upfrontPaymentAmount))}
                    </h4>
                  </div>
                  {payment_plan_checkbox_disabled ? 
                  <div className="billing_row">
                  <p>A payment plan can only be added if there are remaining dues greater than 0.</p>
                </div>
                :
                <div className="billing_row">
                    <Checkbox
                      checked={is_payment_plan}
                      onChange={handleCheckboxChange}
                    >
                      Add a payment plan
                    </Checkbox>
                  </div>
                  }
                  
                  {is_payment_plan && (
                    <>
                      <div
                        className="billing_row"
                        style={{ position: "relative" }}
                      >
                        {check_permissions("payment_plans", "create") &&
                          check_permissions("payment_plans", "update") && (
                            <>
                              <Button
                                size="small"
                                danger
                               className="add-billing-plan"
                                onClick={() => navigate("/payment-plan")}
                              >
                                + Create a payment plan{" "}
                              </Button>{" "}
                            </>
                          )}
                      </div>

                      <div className="billing_row">
                        <Select
                          placeholder="Select Payment Plan"
                          value={selectedPaymentPlan}
                          onChange={onPaymentPlanChange}
                          style={{ width: "100%" }}
                          options={list_payment_plans.map((item) => ({
                            value: item.id,
                            label: item.plan_name,
                          }))}
                        />
                      </div>

                      <div>
                        {PIP_Loader ?(<p style={{ textAlign: "center", padding: "20px 10px" }}>
          <Spin />
        </p>):
                        <>
                        {payment_plans_dates_amount_array.length > 0 &&
                          <>
                        {installmentIndices.map((index) => (
                          <>
                            <GeneratePaymentTable
                              acad_calender_id={atob(acad_calendar_id)}
                              semester_id={atob(sem_id)}
                              student_id={atob(id)}
                              payment_amount_row_array={
                                payment_plans_dates_amount_array
                              }
                              no_of_installments={no_of_installments}
                              set_loader={loader}
                              index={index}
                              plan_id={selectedPaymentPlan}
                              total_bill={total_bill_amount}
                              upfront_payment_amount={upfrontPaymentAmount}
                              onInstallmentUpdate={handleInstallmentUpdate}
                            />
                          </>
                        ))}
                        </>
                        }
                         </>
                        }
                      </div>
                    </>
                  )}

                  <div className="billing_row">
                    <h4>Remaining Dues including Installment Payments </h4>
                    <h4 style={{ color: "#DFA414" }}>
                      {" "}
                      ${" "}
                      {isNaN(
                        Number(total_credit_fee) +
                        Number(semester_registration_fees) +
                        sum_special_fees -
                        Number(upfrontPaymentAmount)
                      )
                      
                        ? 0
                        : (Number(total_credit_fee) +
                        Number(semester_registration_fees) +
                        sum_special_fees -
                        Number(upfrontPaymentAmount) -
                        Number(totalInstallmentAmount)) < 0 ? 0 : (Number(total_credit_fee) +
                        Number(semester_registration_fees) +
                        sum_special_fees -
                        Number(upfrontPaymentAmount) -
                        Number(totalInstallmentAmount))}
                    </h4>
                  </div>

                  <div className="billing_row">
                    <Radio.Group
                      onChange={handleRadioChange}
                      
                      value={parseInt(payment_status) == 1 ? 4 : parseInt(payment_status)}
                    >
                      <Radio value={4}>Not paid</Radio>
                      <Radio value={2}>Fully paid</Radio>
                      <Radio value={3}>Partial paid</Radio>
                    </Radio.Group>
                  </div>
                  {check_permissions("student_final_bill", "create") &&
                    check_permissions("student_final_bill", "update") && (
                      <>
                        {health_insurance?.health_insurance_status == 1 ? (
                          loader ? (
                            <>
                              <Button
                              className="lusisbtn"
                                type="primary"
                                disabled
                                style={{ marginTop: "10px"}}
                              >
                                <Spin
                                  indicator={
                                    <LoadingOutlined
                                      style={{
                                        fontSize: "12px",
                                        color: "#fff",
                                        marginRight: "5px",
                                      }}
                                    />
                                  }
                                />{" "}
                                Publish Bill
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button
                              className="lusisbtn"
                              disabled={PIP_Loader}
                                style={{ marginTop: "10px" }}
                                type="primary"
                                onClick={Add_Update_Publish_Bill}
                              >
                                Publish Bill
                              </Button>
                            </>
                          )
                        ) : (
                          <>
                            <Button
                              type="primary"
                              disabled
                              style={{ marginTop: "10px" }}
                            >
                              Publish Bill
                            </Button>
                            <p style={{ color: "red", marginTop: "15px" }}>
                              Cannot proceed with billing without an active
                              health insurance.
                            </p>
                          </>
                        )}
                      </>
                    )}
                </div>
              </div>
            </div>
          </div>


        </>
      )}
    </div>
  );
};

export default Genratebilling;
