import { Switch } from 'antd'
import React, { useEffect, useState } from 'react'
import { GET_SYSTEM_SETTING, UPDATE_SYSTEM_SETTING } from '../../apis/apis';
import SisLoader from '../../widgets/loader';

const SystemSettings = () => {
    const [loader, setLoader] = useState(true);
    const[student_toggle_show,set_student_toggle_show] = useState("");


    useEffect(() => {
        VIEW_SYSTEM_SETTING_API();
    }, []);


    const VIEW_SYSTEM_SETTING_API = async() => {
        setLoader(true);
        const DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', DATA.token);
         const API_RESPONSE = await GET_SYSTEM_SETTING(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            console.log("API_RESPONSE",API_RESPONSE);
            set_student_toggle_show(API_RESPONSE?.data?.admin_system_setting?.student_toggle_show);
            setLoader(false);
        } else {
            setLoader(false);
        }

    }
    const ADD_SYSTEM_SETTING = async(value) => {
        setLoader(true);
        const DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', DATA.token);
        FORM_DATA.append('student_toggle_show', value == true ? 1 : 0);
         const API_RESPONSE = await UPDATE_SYSTEM_SETTING(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            VIEW_SYSTEM_SETTING_API();
        } else {
            setLoader(false);
        }

    }


  return (
    <>
    {loader ? <SisLoader/> 
    :
    <>
    <h2 style={{color:"#4b4b4b"}}>System Setting</h2><br/>
    <div className='row' style={{border:"1px solid #C7797A",borderRadius:"5px",background:"#FFEAEA",margin:0}}>
    <div className='theme-content-head'style={{margin:"15px"}}>
    <div className='theme-content-left-head'>
    <h3>{student_toggle_show ? "Enable" : "Disable"} All Student Panel Other Functionality</h3>
    <p>(Add-Course, Drop-Course, Withdraw from Semester, Repeat-Course)</p>
    </div>
    <div className='theme-content-right-head'>
    <Switch value={student_toggle_show} onChange={(value) => ADD_SYSTEM_SETTING(value)}/>
    </div>
    </div>
    </div>
    </>
    }
    </>
  )
}

export default SystemSettings