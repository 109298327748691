import React, { useState } from 'react'
import { ADD_STUDENT_NEW_PROGRAM, EDIT_ENROLLMENT } from '../../../apis/apis';
import { Button, DatePicker, notification, Popconfirm, Select, Spin } from 'antd';
import { LoadingOutlined, SmileOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import dayjs from "dayjs";
const StudentNewProgramEnrollment = ({programme_list,entering_year_list,student_program_registration_data,VIEW_API}) => {

      const [add_program_loader, set_add_program_loader] = useState(false);
      const dateFormat = "MM-DD-YYYY";
       const navigate = useNavigate();
      const { id } = useParams();
      const [programme_intended, set_programme_intended] = useState(student_program_registration_data?.enrollments?.programme_intended);
      const [selected_program_concentration, set_selected_program_concentration] = useState(student_program_registration_data?.enrollments?.program_concentration);
      const [errors, set_errors] = useState([]);
      const [entering_year, set_entering_year] = useState(student_program_registration_data?.starting_calendar_id);
      const [confer_date, set_confer_date] = useState("");
      const [entering_class, set_entering_class] = useState("");
      const [programme_status, set_programme_status] = useState("");
      const [academic_standing, set_academic_standing] = useState("");
      const [matriculation_semester, set_matriculation_semester] = useState(student_program_registration_data?.enrollments?.starting_semester_id);
      const [matriculation_date, set_matriculation_date] = useState("");
      const [entering_classification, set_entering_classification] = useState("");
      const [student_type, set_student_type] = useState("");
      const [programme_id, set_programme_id] = useState("");
      const [program_concentration, set_program_concentration] = useState(student_program_registration_data?.program_concentration);
       const [student_status, set_student_status] = useState("");
      // const [programme_list, set_programme_list] = useState([]);
      const [sem_list, set_sem_list] = useState(student_program_registration_data?.starting_calendar_id_semesters);
      // const [entering_year_list, set_entering_year_list] = useState([]);
      const [calender_index, set_calender_index] = useState(0);
    
    
      const calender_change_function = (value) => {
        set_matriculation_semester("");
        const index = entering_year_list.findIndex((item) => item.id === value);
        set_entering_year(value);
        console.log("enter year value dynamic", value);
        set_calender_index(index);
        console.log(index);
        set_sem_list(entering_year_list[index].semesters);
        // entering_year_list[index].semesters.map((item)=>{
        //   console.log(item.title);
        // })
      };
    
      const ADD_API = async() => {
        set_add_program_loader(true);
        const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
        const FORM_DATA = new FormData();
        FORM_DATA.append("token", USER_DATA.token);
        FORM_DATA.append("id", id);
        FORM_DATA.append("program_id", programme_intended);
        FORM_DATA.append("entering_year", entering_year);
        FORM_DATA.append("matriculation_semester", matriculation_semester);
        FORM_DATA.append("matriculation_date", matriculation_date);
        FORM_DATA.append("confer_date", confer_date);
        FORM_DATA.append("programme_status", (programme_status == "null" || programme_status == null) ? " " : programme_status);
        FORM_DATA.append("program_concentration", selected_program_concentration !== 'null' ? selected_program_concentration : '');
        FORM_DATA.append("student_type", (student_type == "null" || student_type == null) ? " " : student_type);
        FORM_DATA.append("entering_classification", (entering_classification == "null" || entering_classification == null) ? " " : entering_classification );
        FORM_DATA.append("academic_standing",academic_standing);
        const API_RESPONSE = await ADD_STUDENT_NEW_PROGRAM(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
          notification.open({
            message: "Success!!",
            description: "Student Enrolled in Program Successfully",
            icon: <SmileOutlined style={{ color: "green" }} />,
          });
          VIEW_API();
        } else {
          set_errors(API_RESPONSE?.data?.errors);
          set_add_program_loader(false);
        }
    
      }
    


  return (
   <>
    <div className="theme-content-head">
    <div className="theme-content-left-head">
      <h3>Program Registration</h3>
    </div>
  </div>
        <div className="row">
          <div className="input-box col-6">
            <label>
              Program/Degree intended<i style={{ color: "red" }}>*</i>
            </label>
            <br></br>
            <Select
            disabled
              placeholder="--Select Programme--"
              style={{ width: "100%" }}
              onChange={(value) => {
                set_programme_intended(value);
              }}
              value={programme_intended ? parseInt(programme_intended) : ""}
            >
              {programme_list?.map((item) => {
                if (item.status === 1 && item.soft_delete === 0) {
                  return (
                    <>
                      <Select.Option value={item.id}>
                        {item.title}
                      </Select.Option>
                    </>
                  );
                }
              })}
            </Select>
            {errors?.program_id && (
              <>
                <span style={{ color: "red" }}>
                  {errors?.program_id[0]}
                </span>
              </>
            )}
          </div>
          <div className="input-box col-6">
            <label>
              Program Concentration
            </label>
            <br></br>
            <Select
              placeholder="--Select Programme--"
              style={{ width: "100%" }}
              onChange={(value) => {
                set_selected_program_concentration(value);
              }}
              disabled={program_concentration?.length === 0}
              value={
                selected_program_concentration === 0
                  ? ""
                  : selected_program_concentration
              }
            >
              {program_concentration &&
                program_concentration?.map((item) => {
                  if (item.status === 1 && item.soft_delete === 0) {
                    return (
                      <>
                        <Select.Option value={item.id}>
                          {item.title}
                        </Select.Option>
                      </>
                    );
                  }
                })}
            </Select>
            {errors?.program_concentration && (
              <>
                <span style={{ color: "red" }}>
                  {errors?.program_concentration[0]}
                </span>
              </>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-3">
            <div className="input-box">
              <label>
                Student Type<i style={{ color: "red" }}>*</i>
              </label>
              <br></br>
              <Select
                placeholder="-- Student Type--"
                style={{ width: "100%"}}
                onChange={(value) => {
                  set_student_type(value);
                }}
                value={student_type}
              >
                {["New", "Transferred", "Returning", "Alumni"].map((item) => {
                  return (
                    <>
                      <Select.Option value={item}>{item}</Select.Option>
                    </>
                  );
                })}
              </Select>
              {errors?.student_type && (
                <>
                  <span style={{ color: "red" }}>
                    {errors?.student_type[0]}
                  </span>
                </>
              )}
            </div>
          </div>
          <div className="col-3">
            <div className="input-box" style={{ width: "100%" }}>
              <label>
                Academic Year<i style={{ color: "red" }}>*</i>
              </label>
              <br />
              <Select
                placeholder="--Select Academic Year--"
                style={{ width: "100%" }}
                onChange={(value)=>calender_change_function(value)}
                value={
                  entering_year ? parseInt(entering_year) : entering_year
                }
                options={entering_year_list?.map((item) => ({
                  value: item.id,
                  calender_id: item.id,
                  label: item.title,
                }))}
              />
              {errors?.entering_year && (
                <>
                  <span style={{ color: "red" }}>
                    The academic year field is required.
                    {/* {errors?.academic_year[0]} */}
                  </span>
                </>
              )}
            </div>
          </div>
          {/* Matriculation Semester */}
          <div className="col-3">
            <div className="input-box" style={{ width: "100%" }}>
              <label>
                Matriculation Semester<i style={{ color: "red" }}>*</i>
              </label>
              <br />

              <Select
                placeholder="--Select Semester--"
                style={{ width: "100%" }}
                disabled={Boolean ? !entering_year : entering_year} // Disable until a academic year is selected
                onChange={(value) => {
                  set_matriculation_semester(value);
                }}
                value={matriculation_semester}
                options={sem_list?.map((item) => ({
                  value: item.id,
                  label: item.title,
                }))}
              />

              {errors?.matriculation_semester && (
                <>
                  <span style={{ color: "red" }}>
                    {errors?.matriculation_semester[0]}
                  </span>
                </>
              )}
            </div>
          </div>

          {/* Matriculation Date */}
          <div className="col-3">
            <div className="input-box" style={{ width: "100%" }}>
              <label>
                Matriculation Date<i style={{ color: "red" }}>*</i>
              </label>
              <br />

              <DatePicker
                format={dateFormat}
                value={matriculation_date && dayjs(matriculation_date)}
                onChange={(date, dateString) =>
                  set_matriculation_date(dateString)
                }
                style={{ width: "100%" }}
              />

              {errors?.matriculation_date && (
                <>
                  <span style={{ color: "red" }}>
                    {errors?.matriculation_date[0]}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="row">
          {/* Confer Date */}
          <div className="col-3">
            <div className="input-box" style={{ width: "100%" }}>
              <label>Confer Date</label>
              <br />

              <DatePicker
                format={dateFormat}
                value={confer_date && dayjs(confer_date)}
                onChange={(date, dateString) => set_confer_date(dateString)}
                style={{ width: "100%" }}
              />
              {errors?.confer_date && (
                <>
                  <span style={{ color: "red" }}>
                    {errors?.confer_date[0]}
                  </span>
                </>
              )}
            </div>
          </div>
          {/* Program Status */}
          <div className="col-3">
            <div className="input-box" style={{ width: "100%" }}>
              <label>
                Programme Status <i style={{ color: "red" }}>*</i>
              </label>
              <br />
              <Select
                style={{ width: "100%" }}
                placeholder="Select Program Status"
                onChange={(value) => {
                  set_programme_status(value);
                }}
                value={programme_status}
                required
              >
                <Select.Option value="Active">Active</Select.Option>
                <Select.Option value="Inactive">Inactive</Select.Option>
                <Select.Option value="Completed">Completed</Select.Option>
                <Select.Option value="Withdraw">Withdraw</Select.Option>
                <Select.Option value="Dismissed">Dismissed</Select.Option>
                <Select.Option value="Suspended">Suspended</Select.Option>
                <Select.Option value="Probation">Probation</Select.Option>
                <Select.Option value="Deferred">Deferred</Select.Option>
                <Select.Option value="Readmitted">Readmitted</Select.Option>
                <Select.Option value="Leave of Absence">
                  Leave of Absence
                </Select.Option>
                <Select.Option value="Transferred Out">
                  Transferred Out
                </Select.Option>
                <Select.Option value="Discontinued">
                  Discontinued
                </Select.Option>
                <Select.Option value="Graduated with Honors">
                  Graduated with Honors
                </Select.Option>
                <Select.Option value="Non-Degree Seeking">
                  Non-Degree Seeking
                </Select.Option>
                <Select.Option value="Conditional Admission">
                  Conditional Admission
                </Select.Option>
              </Select>
              {errors?.programme_status && (
                <>
                  <span style={{ color: "red" }}>
                    {errors?.programme_status[0]}
                    {/* The program status field is required. */}
                  </span>
                </>
              )}
            </div>
          </div>

          {/* Entering Classification */}
          <div className="col-3">
            <div className="input-box" style={{ width: "100%" }}>
              <label>
                Entering Classification<i style={{ color: "red" }}>*</i>
              </label>
              <br />
              <Select
                onChange={(value) => {
                  set_entering_classification(value);
                }}
                value={entering_classification}
                style={{ width: "100%" }}
                placeholder="Select Entering Classifications"
                required
              >
                <Select.Option value="undergraduate">
                  Undergraduate
                </Select.Option>
                <Select.Option value="graduate">Graduate</Select.Option>
              </Select>
              {errors?.entering_classification && (
                <>
                  <span style={{ color: "red" }}>
                    {errors?.entering_classification[0]}
                  </span>
                </>
              )}
            </div>
          </div>
          {/* Academic Standing */}
          <div className="col-3">
            <div className="input-box" style={{ width: "100%" }}>
              <label>
                Academic Standing
              </label>
              <br />
              <Select
                style={{ width: "100%" }}
                placeholder="Select Program Status"
                onChange={(value) => {
                  set_academic_standing(value);
                }}
                value={academic_standing}
                required
              >
                <Select.Option value="Good Standing">
                  Good Standing
                </Select.Option>
                <Select.Option value="Academic Warning">
                  Academic Warning
                </Select.Option>
                <Select.Option value="Academic Probation">
                  Academic Probation
                </Select.Option>
                <Select.Option value="Continued Probation">
                  Continued Probation
                </Select.Option>
                <Select.Option value="Academic Suspension">
                  Academic Suspension
                </Select.Option>
                <Select.Option value="Academic Dismissal">
                  Academic Dismissal
                </Select.Option>
                <Select.Option value="Conditional Admission">
                  Conditional Admission
                </Select.Option>
                <Select.Option value="Reinstated on Probation">
                  Reinstated on Probation
                </Select.Option>
                <Select.Option value="Dean’s List/Academic Honors">
                  Dean’s List/Academic Honors
                </Select.Option>
                <Select.Option value="Academic Progress Hold">
                  Academic Progress Hold
                </Select.Option>
                <Select.Option value="Disciplinary Suspension/Dismissal">
                  Disciplinary Suspension/Dismissal
                </Select.Option>
              </Select>
              {errors?.academic_standing && (
                <>
                  <span style={{ color: "red" }}>
                    {errors?.academic_standing[0]}
                    {/* The program status field is required. */}
                  </span>
                </>
              )}
            </div>
          </div>
        </div>
        {/* <div className="row">
            <div className="col-3">
              <div className="input-box" style={{ width: "100%" }}>
                <label>
                  Student status<i style={{ color: "red" }}>*</i>
                </label>
                <br></br>
                <Select
                  placeholder="Select Status"
                  style={{ width: "100%" }}
                  value={student_status ? parseInt(student_status) : ""}
                  onChange={(value) => set_student_status(value)}
                >
                  <Select.Option value={2}>Enrolled</Select.Option>
                  <Select.Option value={1}>
                    Ready for Programme registration
                  </Select.Option>
                </Select>
                {errors?.student_status && (
                  <>
                    <span style={{ color: "red" }}>
                      {errors?.student_status[0]}
                    </span>
                  </>
                )}
              </div>
            </div>
        </div> */}            
        <div className="input-box">
        <Popconfirm
                    title={"Are you sure you want to enroll a student into a program?"}
                    description="The student will be enrolled into the program."
                    onConfirm={ADD_API}
                    okText="Yes"
                    cancelText="No"
                  >
                {add_program_loader ? (
                  <>
                    <Button type="primary" className="lusisbtn" style={{width:"156px"}}>
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: "12px",
                              color: "#fff",
                              marginRight: "5px",
                            }}
                          />
                        }
                      />{" "}
                      Enroll Student
                    </Button>
                  </>
                ) : (
                  <>
                    <Button className="lusisbtn" style={{width:"156px"}} type="primary" 
                    // onClick={ADD_API}
                    >
                      Enroll Student
                    </Button>
                  </>
                )}
                </Popconfirm>
        </div>
        
  </>
  )
}

export default StudentNewProgramEnrollment;