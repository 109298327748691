/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import {
  DownOutlined,
  RightOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import {
  Button,
  Checkbox,
  Input,
  Collapse,
  Form,
  InputNumber,
  DatePicker,
  Space,
  Option,
  Popconfirm,
  Select,
  Spin,
  Table,
  notification,
  Pagination,
} from "antd";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  SEMESTERS_LIST,
  SEMESTER_REGISTRATION_VIEW,
  SEMESTER_REGISTRATION_VIEW_LISTING_SEARCH,
  STUDENTS_LIST,
} from "../../apis/apis";
import SisLoader from "../../widgets/loader";
const SemesterRegistrationView = () => {
  const { id, sem_id } = useParams();

  const history = useLocation();
  const navigate = useNavigate();
  const [loader, set_loader] = useState(true);
  const [errors, set_errors] = useState([]);
  const [sem_courses_list, set_sem_courses_list] = useState([]);
  const [table_list, set_table_list] = useState([]);
  const [page_loader, set_page_loader] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [last_page, set_last_page] = useState(1);
  const [total, set_total] = useState("");
  const [per_page, set_per_page] = useState("");
  const [search_error, set_search_error] = useState("")
    const debounceTimeout = useRef(null);
    const errorTimeoutRef = useRef(null);

  const check_permissions = (module_name, type) => {
    const localStorgeData = JSON.parse(localStorage.getItem("sis_user_data"));
    if (localStorgeData.role === 2074) {
      return true;
    }
    var check_permissions = [];
    if (type === "view") {
      check_permissions = localStorgeData.role_permissions.permissions.filter(
        (permission) => permission.name === module_name && permission.view
      );
    }
    if (type === "create") {
      check_permissions = localStorgeData.role_permissions.permissions.filter(
        (permission) => permission.name === module_name && permission.create
      );
    }
    if (type === "update") {
      check_permissions = localStorgeData.role_permissions.permissions.filter(
        (permission) => permission.name === module_name && permission.update
      );
    }
    if (type === "status") {
      check_permissions = localStorgeData.role_permissions.permissions.filter(
        (permission) => permission.name === module_name && permission.status
      );
    }
    if (type === "delete") {
      check_permissions = localStorgeData.role_permissions.permissions.filter(
        (permission) => permission.name === module_name && permission.delete
      );
    }

    if (check_permissions.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const debounce = (callback, delay) => {
    return (...args) => {
        clearTimeout(debounceTimeout.current);
        debounceTimeout.current = setTimeout(() => {
            callback(...args);
        }, delay);
    };
};

  const handleSearch = debounce((query) => {
    if(query != "")
    {
      SEARCH_LIST_API(query);
    }
    else{
      REGISTER_LIST_API(currentPage)
    }
  }, 800);

  const handleInputChange = (event) => {
    const value = event.target.value;
    setSearchQuery(value);
    if (errorTimeoutRef.current) {
      clearTimeout(errorTimeoutRef.current);
    }
    if (value === "") {
      set_search_error("");
      handleSearch(value);
      return;
    }
    if (value.length < 3) {
      errorTimeoutRef.current = setTimeout(() => {
      set_search_error("Please enter at least 3 characters.")
    }, 800);
      return;
    }
    set_search_error("");
    handleSearch(value);
  };


 const SEARCH_LIST_API = async (UserQuery) => {
     set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("acad_calender_id", atob(id));
    FORM_DATA.append("semester_id", atob(sem_id));
    FORM_DATA.append("search", UserQuery);
    const API_RESPONSE = await SEMESTER_REGISTRATION_VIEW_LISTING_SEARCH(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      // Filter out objects where semester_register_status is true
      const filteredData =
        API_RESPONSE?.data?.semester_registration?.data?.filter(
          (item) =>
            parseInt(item?.matriculation_semester) === atob(sem_id) ||
            parseInt(item?.matriculation_semester) <= atob(sem_id)
        );
      set_table_list(filteredData);
      setFilteredData(filteredData);
      setCurrentPage(API_RESPONSE?.data?.semester_registration?.current_page);
      set_last_page(API_RESPONSE?.data?.semester_registration?.last_page);
      set_total(API_RESPONSE?.data?.semester_registration?.total);
      set_per_page(API_RESPONSE?.data?.semester_registration?.per_page);
      set_loader(false);
    } else {
      set_loader(false);
    }
  };


  const LIST_API = async () => {
    set_page_loader(true);
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("calendar_id", id);
    const API_RESPONSE = await SEMESTERS_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_sem_courses_list(API_RESPONSE?.data?.semesters);

      // var first_semester = btoa(API_RESPONSE?.data?.semesters[0]?.id);
      // if (sem_id) {
      //   first_semester = sem_id;
      // }
      // set_loader(false);
      set_page_loader(false);
    } else {
      // set_loader(false);
    }
  };
  const columns = [
    
    {
      title: "ID",
      dataIndex: "id",
      render: (text, record) => {
        return <> {record?.student?.id}</>;
      },
      sorter: (a, b) => a?.student?.id - b?.student?.id,
    },
    {
      title: "Student ID",
      dataIndex: "student_id",
      render: (text, record) => {
        return <> {record?.student?.student_id ? record?.student?.student_id : "-"}</>;
      },
      sorter: (a, b) => a?.student?.student_id - b?.student?.student_id,
    },
    {
      title: "Name",
      render: (text, record) => {
        if (record.semester_register_status) {
          return (
            <>
              {record.student.first_name + " "}
              {record.student.middle_name != "null" &&
                record.student.middle_name &&
                record.student.middle_name + " "}
              {record.student.last_name}
            </>
          );
        } else {
          return (
            <>
              {record.student.first_name + " "}
              {record.student.middle_name != "null" &&
                record.student.middle_name &&
                record.student.middle_name + " "}
              {record.student.last_name}
              <span
                style={{
                  backgroundColor: "red",
                  fontSize: "10px",
                  position: "relative",
                  color: "#fff",
                  padding: "2px 5px",
                  top: "-6px",
                  left: "3px",
                  borderRadius: "5px",
                }}
              >
                New
              </span>
            </>
          );
        }
      },
    },
    // {
    //   title: "Application No",
    //   dataIndex: "application_no",
    //   render: (text, record) => {
    //     return <> {record?.student?.application_no}</>;
    //   },
    // },
    {
      title: "Phone",
      dataIndex: "phone",
      render: (text, record) => {
        return <> {record?.student?.phone}</>;
      },
    },
    {
      title: "Email",
      dataIndex: "primary_email",
      render: (text, record) => {
        return <> {record?.student?.primary_email}</>;
      },
    },
    {
      title: "Semester Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => {
        return (
          <>
            {" "}
            {record?.semester_register?.registration_status ? (
              <>
                {record?.semester_register?.registration_status == "null" ? (
                  "Not Registered"
                ) : (
                  <>
                    {record?.semester_register?.registration_status == 1 &&
                      "Ready for Payment"}
                    {record?.semester_register?.registration_status == 2 &&
                      "Paid"}
                    {record?.semester_register?.registration_status == 3 &&
                      "Partial Paid"}
                    {record?.semester_register?.registration_status == 4 &&
                      "Not Paid"}
                  </>
                )}
              </>
            ) : (
              "Not Registered"
            )}
          </>
        );
      },
    },
    {
      title: "Action",
      key: "Action",
      render: (text, record) => {
        return (
          <>
            {(check_permissions("semester_registrations", "create") ||
              check_permissions("semester_registrations", "update")) && (
              <Button
                type="primary"
                size="small"
                onClick={() =>
                  navigate(
                    "/semester-registration-student/" +
                      id +
                      "/" +
                      sem_id +
                      "/" +
                      btoa(record.student.id)
                  )
                }
                style={{ marginRight: "5px", background: "#b64141" }}
              >
                Semester Registration
              </Button>
            )}
          </>
        );
      },
    },
  ];

  const REGISTER_LIST_API = async (UserSearchPage) => {
     set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("acad_calender_id", atob(id));
    FORM_DATA.append("semester_id", atob(sem_id));
    FORM_DATA.append("page", UserSearchPage ? UserSearchPage : currentPage);
    const API_RESPONSE = await SEMESTER_REGISTRATION_VIEW(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      // Filter out objects where semester_register_status is true
      const filteredData =
        API_RESPONSE?.data?.semester_registration?.data?.filter(
          (item) =>
            parseInt(item?.matriculation_semester) === atob(sem_id) ||
            parseInt(item?.matriculation_semester) <= atob(sem_id)
        );
      set_table_list(filteredData);
      setFilteredData(filteredData);
      setCurrentPage(API_RESPONSE?.data?.semester_registration?.current_page);
      set_last_page(API_RESPONSE?.data?.semester_registration?.last_page);
      set_total(API_RESPONSE?.data?.semester_registration?.total);
      set_per_page(API_RESPONSE?.data?.semester_registration?.per_page);
      set_loader(false);
    } else {
      set_loader(false);
    }
  };

  useEffect(() => {
    LIST_API();
    REGISTER_LIST_API();
  }, [history]);

  // const pagination_changes = (page) => {
  //   setCurrentPage(page);
  // };

  const pagination_changes = (UserSearchPage) => {
    setCurrentPage(UserSearchPage);
    if(searchQuery == "")
    {
      REGISTER_LIST_API(UserSearchPage);
    }
    else{
      SEARCH_LIST_API(searchQuery)
    }
    
  };
  return (
    <>
      {loader ? (
        <SisLoader />
      ) : (
        <>
          <div>
            <div className="theme-content-head">
              <div className="theme-content-left-head">
                <h3>Semester Registration</h3>
              </div>
              <div className="theme-content-right-head"></div>
            </div>
            {/* <div className='sis-tab-menu'>
                    <span className='active'>Academic Calendar</span>
                    <span  onClick={() => navigate('/semester-courses/')}>Schedule</span>
                </div> */}
            <ul className="semester-tabs">
              {sem_courses_list?.length > 0 && (
                <>
                  {sem_courses_list?.map((item, index) => (
                    <>
                      {sem_id ? (
                        <>
                          {item.id === parseInt(atob(sem_id)) ? (
                            <>
                              <li
                                className="active"
                                onClick={() =>
                                  navigate(
                                    "/semester-registration-view/" +
                                      id +
                                      "/" +
                                      btoa(item.id)
                                  )
                                }
                              >
                                {item.title}
                              </li>
                            </>
                          ) : (
                            <>
                              <li
                                onClick={() =>{
                                  setCurrentPage(1);
                                  setSearchQuery("");
                                  set_search_error("");
                                  navigate(
                                    "/semester-registration-view/" +
                                      id +
                                      "/" +
                                      btoa(item.id)
                                  )
                                }
                              }
                              >
                                {item.title}
                              </li>
                            </>
                          )}
                        </>
                      ) : (
                        <>
                          <li
                            className={index === 0 ? "active" : ""}
                            onClick={() =>
                              navigate(
                                "/semester-registration-view/" +
                                  id +
                                  "/" +
                                  sem_id
                              )
                            }
                          >
                            {item.title}
                          </li>
                        </>
                      )}
                    </>
                  ))}
                </>
              )}
            </ul>
            <div
                className="input-box"
                style={{ maxWidth: "250px", marginTop: "25px" }}
              >
                <Input
                  type="text"
                  placeholder="Search..."
                  value={searchQuery}
                  onChange={handleInputChange}
                />
               {search_error && (
                  <>
                  <br/>
                  <span style={{ color: "red" }}>{search_error}</span>
                  </>
                )}
              </div>
            {filteredData.length > 0 ? (
            <>
                      <Table
                        columns={columns}
                        dataSource={filteredData}
                        pagination={false}
                      />
                      <div style={{ float: "right", marginTop: "20px" }}>
                        <Pagination
                          onChange={pagination_changes}
                          defaultCurrent={parseInt(currentPage)}
                          total={parseInt(total)}
                          pageSize={parseInt(per_page)}
                        />
                      </div>
                    </>
                  ) : (
                    <div style={{ textAlign: "center", marginTop: "20px", color:"red" }}>
                      No students found
                    </div>
                  )}
            </div>
        </>
      )}
    </>
  );
};

export default SemesterRegistrationView;
