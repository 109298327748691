import { Button, Table, Tooltip } from 'antd';
import React from 'react'
import MultipleCoursePrint from '../../MultipleCoursePrint/multipleCoursePrint';
import { CloudDownloadOutlined, FilePdfOutlined } from '@ant-design/icons';
import { BACKEND_URL } from '../../../../config/config';

const AdvancedEnrollandRegisReportSummaryTable = ({table_list,academic_year,semester,programme_intended,certificate_programs,student_type,admission_status,student_category,age,gender,identify}) => {
    const columns = [
        {
          title: "Student Name",
         render: (text, record) => {
            return (
              <>
                {record?.student?.first_name}{" "}{record?.student?.middle_name == "null"? "" : record?.student?.middle_name }{" "}{record?.student?.last_name}
              </>
            );
          },
        },
        {
          title: "Student Id",
          render: (text, record) => {
            return (
              <>
                {record?.student?.student_id}
              </>
            );
          },
        },
        {
          title: "Email",
          render: (text, record) => {
            return (
              <>
                {record?.student?.primary_email}
              </>
            );
          },
        },
        {
          title: "Age",
          render: (text, record) => {
            return (
              <>
               <span>{record?.age}</span>
              </>
            );
          },
        },
        {
            title: "Gender",
            render: (text, record) => {
              return (
                <>
                 <span style={{textTransform:"capitalize"}}>{record?.student?.gender}</span>
                </>
              );
            },
          },
          {
            title: "Ethnicity",
            render: (text, record) => {
              return (
                <>
                 {record?.student?.ethnicity ? record?.student?.ethnicity : "-"}
                </>
              );
            },
          },
          {
            title: "Course Enrollment Details",
            render: (text, record) => {
              return (
                <>
               {record?.student_by_semester_courses_detail?.length > 0 &&
               <MultipleCoursePrint student_by_semester_courses_detail={record?.student_by_semester_courses_detail} report_type ="enroll_and_regis"/>
               }
                </>
              );
            },
          },
          {
            title: "Financial Aid",
            render: (text, record) => {
              return (
                <>
               {(record?.student?.financial_aid && record?.student?.financial_aid != "null" ) ? record?.student?.financial_aid : "No"}
                </>
              );
            },
          },
          {
            title: "Student Category",
            render: (text, record) => {
              return (
                <>
               {(record?.student?.student_category && record?.student?.student_category != "null" ) ? record?.student?.student_category : "-"}
                </>
              );
            },
          },
          {
            title: "Student Athlete",
            render: (text, record) => {
              return (
                <>
               {(record?.student?.student_athlete && record?.student?.student_athlete != "null" ) ? record?.student?.student_athlete == 1 ? "Yes" : "No" : "No"}
                </>
              );
            },
          },
      ];
      function calculateAge(dob) {
        const today = new Date();
        const birthDate = new Date(dob);
        let age = today.getFullYear() - birthDate.getFullYear();
        const monthDifference = today.getMonth() - birthDate.getMonth();
    
        // Adjust if the birthday hasn't occurred yet this year
        if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
            age--;
        }
    
        return age;
    }
  return (
    <>
    <div style={{display:"inline-block", float:"right", marginRight:"10px"}}>
                    {/* CSV Download button  */}
                    <Tooltip title="Download Enrollment & Registration Report CSV">
                        <Button type='primary'
                        onClick={() => window.open(BACKEND_URL + '/csv-enrollment-registration-reports/' + JSON.parse(localStorage.getItem('sis_user_data')).token +'/'+ academic_year +'/'+ semester+'/'+ programme_intended +'/'+ certificate_programs +'/'+ student_type+'/'+ admission_status +'/'+ student_category+'/'+ age+'/'+ gender +'/'+ identify)} 
                         ghost style={{ marginLeft: "5px" }}><CloudDownloadOutlined /></Button>
                    </Tooltip>
                    {/* PDF Download button  */}
                    <Tooltip title="Download Enrollment & Registration Report PDF">
                        <Button type='primary'
                         onClick={() => window.open(BACKEND_URL + '/pdf-advance-enrollment-registration-report/' + JSON.parse(localStorage.getItem('sis_user_data')).token +'/'+ academic_year +'/'+ semester+'/'+ programme_intended +'/'+ certificate_programs +'/'+ student_type+'/'+ admission_status +'/'+ student_category+'/'+ age+'/'+ gender +'/'+ identify)}
                         ghost style={{ marginLeft: "5px" }}><FilePdfOutlined /></Button>
                    </Tooltip>
                </div>
    <Table className="vertical-align-top-table" columns={columns} pagination={true} dataSource={table_list}/>
    </>
  )
}

export default AdvancedEnrollandRegisReportSummaryTable;