import React, { useEffect, useRef, useState, useMemo } from "react";
import {
  Button,
  Checkbox,
  Input,
  Radio,
  Select,
  Spin,
  Table,
  notification,
  DatePicker,
  Space,
  Divider,
  List,
  Typography,
} from "antd";
import { InputNumber } from "antd";
import { PlusCircleOutlined } from "@ant-design/icons";
import {
  LoadingOutlined,
  CloseOutlined,
  CloudUploadOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import {
  SIS_STORAGE,
  CALENDARS_LIST,
  PROGRAMS_LIST,
  STUDENTS_ENROLLMENTS_LIST,
  STUDENTS_ENROLLMENTS_VIEW,
  EDIT_ENROLLMENT,
} from "../../apis/apis";

import SisLoader from "../../widgets/loader";
import Item from "antd/es/list/Item";
import { useNavigate, useParams } from "react-router-dom";
import countryList from "react-select-country-list";
import customParseFormat from "dayjs/plugin/customParseFormat";
import dayjs from "dayjs";
import "./style.css";

import StudentImage from "../../images/user.png";
import StudentMultipleProgramRow from "./StudentMultipleProgram/studentSingleProgramRow";
import StudentSingleProgramRow from "./StudentMultipleProgram/studentSingleProgramRow";
import StudentMultipleProgram from "./StudentMultipleProgram/studentMultipleProgram";
import StudentNewProgramEnrollment from "./StudentNewProgramEnrollment/studentNewProgramEnrollment";

dayjs.extend(customParseFormat);

const AddEnrollment = () => {
  const dateFormat = "MM-DD-YYYY";
  const { id } = useParams();
  const navigate = useNavigate();
  const [loader, set_loader] = useState(false);
  const [errors, set_errors] = useState([]);
  const [
    student_program_registration_data,
    set_student_program_registration_data,
  ] = useState([]);
  const [page_loader, set_page_loader] = useState(false);
  const [enroll_id, set_enroll_id] = useState("");
  const [url_image, set_url_image] = useState("");

  // Form Data
  const [image, set_image] = useState("");
  const [first_name, set_first_name] = useState("");
  const [middle_name, set_middle_name] = useState("");
  const [last_name, set_last_name] = useState("");
  const [student_id, set_student_id] = useState("");
  const [application_no, set_application_no] = useState("");
  const [student_status, set_student_status] = useState("");
  const [programme_list, set_programme_list] = useState([]);
  const [entering_year_list, set_entering_year_list] = useState([]);
  const [sem_list, set_sem_list] = useState([]);

  const VIEW_API = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("id", id);
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("image", image);
    const VIEW_API_RESPONSE = await STUDENTS_ENROLLMENTS_VIEW(FORM_DATA);
    if (VIEW_API_RESPONSE?.data?.status) {
      if (VIEW_API_RESPONSE?.data?.enrollments) {
        set_student_program_registration_data(VIEW_API_RESPONSE?.data);
        set_sem_list(VIEW_API_RESPONSE?.data?.semester_details_with_calendar);
        set_application_no(
          VIEW_API_RESPONSE?.data?.enrollments?.application_no
        );
        set_image(VIEW_API_RESPONSE?.data?.enrollments?.image);
        set_enroll_id(VIEW_API_RESPONSE?.data?.enrollments?.id);
        set_student_id(VIEW_API_RESPONSE?.data?.enrollments?.student_id);
        set_first_name(VIEW_API_RESPONSE?.data?.enrollments?.first_name);
        set_middle_name(VIEW_API_RESPONSE?.data?.enrollments?.middle_name);
        set_last_name(VIEW_API_RESPONSE?.data?.enrollments?.last_name);
        set_student_status(
          VIEW_API_RESPONSE?.data?.enrollments?.student_status
        );
      }
      set_loader(false);
    }
  };

  const PROGRAMS_LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    const API_RESPONSE = await PROGRAMS_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_programme_list(API_RESPONSE?.data?.programmes);
    }
  };

  const LIST_API = async () => {
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    const API_RESPONSE = await CALENDARS_LIST(FORM_DATA);
    if (API_RESPONSE?.data?.status) {
      set_entering_year_list(API_RESPONSE?.data?.calendars);
    }
  };

  useEffect(() => {
    VIEW_API();
    PROGRAMS_LIST_API();
    LIST_API();
  }, [page_loader]);

  const handleImageChange = (e) => {
    const files = e.target.files;
    if (
      (files[0].type === "image/jpeg" ||
        files[0].type === "image/jpg" ||
        files[0].type === "image/png") &&
      files[0].size < 2000001
    ) {
      set_url_image(files[0]);
    } else {
      notification.open({
        message: "Not Allowed",
        description:
          "Please check image File format must be .jpg or .png and Max. file size 2 Mb",
        icon: <SmileOutlined style={{ color: "red" }} />,
      });
    }
  };
  const handleRemoveImage = (e) => {
    set_url_image("");
  };

  const ADD_API = async () => {
    set_loader(true);
    const USER_DATA = JSON.parse(localStorage.getItem("sis_user_data"));
    const FORM_DATA = new FormData();
    FORM_DATA.append("token", USER_DATA.token);
    FORM_DATA.append("id", id);
    FORM_DATA.append("image", image);
    FORM_DATA.append("first_name", first_name);
    FORM_DATA.append("middle_name", middle_name);
    FORM_DATA.append("last_name", last_name);
    FORM_DATA.append("student_id", student_id);
    FORM_DATA.append("student_status", student_status);
    const API_RESPONSE = await EDIT_ENROLLMENT(FORM_DATA);
    console.log(API_RESPONSE);
    if (API_RESPONSE.data.status) {
      notification.open({
        message: "Success!!",
        description: "Student Enrollment Successfully added.",
        icon: <SmileOutlined style={{ color: "green" }} />,
      });
      navigate("/enrollments-list");
    } else {
      set_errors(API_RESPONSE.data.errors);
      set_loader(false);
    }
  };

  return (
    <>
      {loader ? (
        <SisLoader />
      ) : (
        <>
          <div>
            <div className="theme-content-head">
              <div className="theme-content-left-head">
                <h3 style={{ marginLeft: "10px" }}>
                  Register Student in Program
                </h3>
              </div>
              <div className="theme-content-right-head"></div>
            </div>
            <div className="common-form">
              <div className="col-8" style={{ width: "100%" }}>
                {/* Basic Information */}
                <>
                  <div className="theme-content-left-head">
                    <h3>Basic Information</h3>
                  </div>
                  <div className="basic-information">
                    {/* Student Image */}
                    <div className="input-box" style={{ position: "relative" }}>
                      <label htmlFor="address">Image</label>
                      <br></br>
                      {/* <label className='upload-box'> <input type="file" id="upload-images" accept="image/*" style={{ height: "0px", overflow: "hidden" }} onChange={handleImageChange} /> <CloudUploadOutlined /> Upload Image</label> */}
                      {url_image ? (
                        <>
                          <div
                            className="image-box"
                            style={{
                              backgroundImage:
                                "url(" + URL.createObjectURL(url_image) + ")",
                            }}
                          ></div>
                          <button
                            className="image-remove"
                            onClick={() => handleRemoveImage()}
                          >
                            <CloseOutlined />
                          </button>
                        </>
                      ) : (
                        <>
                          {image && (
                            <>
                              <div
                                className="image-box"
                                style={{
                                  backgroundImage:
                                    "url(" +
                                    SIS_STORAGE +
                                    "/enrollments/" +
                                    image +
                                    ")",
                                }}
                              ></div>
                            </>
                          )}
                        </>
                      )}

                      {errors?.image && (
                        <>
                          <span style={{ color: "red" }}>
                            {errors?.image[0]}
                          </span>
                        </>
                      )}
                    </div>

                    {/* First Name */}
                    <div className="input-box" style={{ width: "200px" }}>
                      <label>
                        First Name<i style={{ color: "red" }}>*</i>
                      </label>
                      <br></br>
                      <Input value={first_name} disabled />

                      {/* <Select
                                    placeholder="Select Student"
                                    style={{ width: '100%' }}
                                    onChange={(value) => { set_student_first_name(value); set_student_info(student_list.filter(obj => obj.id === value)) }}
                                    value={student_id}
                                    disabled
                                >
                                    {student_list.map((item) => {
                                        if (item.status === 1 && item.soft_delete === 0) {
                                            return (
                                                <Select.Option key={item.id} value={item.id}>
                                                    {`${item.first_name}`}
                                                </Select.Option>
                                            );
                                        }
                                    })}
                                </Select> */}

                      {errors?.first_name && (
                        <>
                          <span style={{ color: "red" }}>
                            {errors?.first_name[0]}
                          </span>
                        </>
                      )}
                    </div>

                    {/* Middle Name */}
                    <div className="input-box" style={{ width: "200px" }}>
                      <label>Middle Name</label>
                      <br></br>
                      <Input
                        value={middle_name === "null" ? "" : middle_name}
                        disabled
                      />
                      {errors?.middle_name && (
                        <>
                          <span style={{ color: "red" }}>
                            {errors?.middle_name[0]}
                          </span>
                        </>
                      )}
                    </div>
                    {/* Last Name */}
                    <div className="input-box" style={{ width: "200px" }}>
                      <label>
                        Last Name<i style={{ color: "red" }}>*</i>
                      </label>
                      <br></br>
                      <Input value={last_name} disabled />
                      {errors?.last_name && (
                        <>
                          <span style={{ color: "red" }}>
                            {errors?.last_name[0]}
                          </span>
                        </>
                      )}
                    </div>
                    {/* Student ID */}
                    <div className="input-box" style={{ width: "200px" }}>
                      <label>
                        Student ID<i style={{ color: "red" }}>*</i>
                      </label>
                      <br></br>
                      <Input
                        // onChange={(value) => { set_last_name(value); set_student_info(last_name.filter(obj => obj.id === value)) }}
                        value={student_id}
                        disabled
                      />
                      {errors?.student_id && (
                        <>
                          <span style={{ color: "red" }}>
                            {errors?.student_id[0]}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </>
                <br />
                {/* Student status */}
                {/* <>
                  <div style={{ width: "100%" }}>
                    <div className="input-box" style={{ width: "200px" }}>
                      <label>
                        Student status<i style={{ color: "red" }}>*</i>
                      </label>
                      <br></br>
                      <Select
                      disabled
                        placeholder="Select Status"
                        style={{ width: "100%" }}
                        value={parseInt(student_status)}
                        onChange={(value) => set_student_status(value)}
                      >
                        <Select.Option value={2}>Enrolled</Select.Option>
                        <Select.Option value={1}>
                          Ready for Programme registration
                        </Select.Option>
                      </Select>
                      {errors?.student_status && (
                        <>
                          <span style={{ color: "red" }}>
                            {errors?.student_status[0]}
                          </span>
                        </>
                      )}
                    </div>
                  </div>
                </> */}
                <br />
                <hr />
                <br />
                {student_program_registration_data?.programme?.length > 0 ? 
                <>
                <StudentMultipleProgram
                  student_program_registration_data={
                    student_program_registration_data
                  }
                  set_page_loader={set_page_loader}
                  programme_list={programme_list}
                  entering_year_list={entering_year_list}
                  sem_list={sem_list}
                  page_loader={page_loader}
                />
                </>
                :
                <StudentNewProgramEnrollment
                programme_list={programme_list}
                entering_year_list={entering_year_list}
                student_program_registration_data={
                  student_program_registration_data
                }
                VIEW_API={VIEW_API}
                />
                 }
                {/* <br /> */}
                {/* <hr />
              <br /> */}

                {/* <br />
              <div className="input-box">
                {loader ? (
                  <>
                    <Button type="primary" className="lusisbtn" style={{width:"156px"}}>
                      <Spin
                        indicator={
                          <LoadingOutlined
                            style={{
                              fontSize: "12px",
                              color: "#fff",
                              marginRight: "5px",
                            }}
                          />
                        }
                      />{" "}
                      Enrol Student
                    </Button>
                  </>
                ) : (
                  <>
                    <Button className="lusisbtn" style={{width:"156px"}} type="primary" onClick={ADD_API}>
                      Enrol Student
                    </Button>
                  </>
                )}
              </div> */}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AddEnrollment;
