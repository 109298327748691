    /* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from 'react';
import { Button, Checkbox, Input, Popconfirm, Select, Spin, Table, notification } from 'antd';
import './style.css';
import { CloseOutlined, CloudUploadOutlined, DeleteOutlined, EditOutlined, EyeInvisibleOutlined, EyeTwoTone, LoadingOutlined, SmileOutlined, StopOutlined } from '@ant-design/icons';
import { STATUS_SEMESTER, DELETE_SEMESTER, SEMESTERS_LIST, EDIT_CALENDAR, sisDateFormat} from '../../apis/apis';
import SisLoader from '../../widgets/loader';
import Item from 'antd/es/list/Item';
import { useNavigate, useParams } from 'react-router-dom';
import { Editor } from '@tinymce/tinymce-react';

const CalendarSchedule = () => {
    const dateFormat = 'MM-DD-YYYY';
    const { calendar_id } = useParams();
    const navigate = useNavigate();
    const [loader, set_loader] = useState(false);
    const [errors, set_errors] = useState([]);

    const [table_list, set_table_list] = useState([]);
      // Form State
    const [title, set_title] = useState('');
    const [description, set_description] = useState('');
    const [calendar_year, set_calendar_year] = useState('');
    const [calendar_start, set_calendar_start] = useState('');
    const [calendar_end, set_calendar_end] = useState('');

    const check_permissions = (module_name, type) => {
        const localStorgeData = JSON.parse(localStorage.getItem('sis_user_data'));
        if(localStorgeData.role === 2074){
            return true;
        }
        var check_permissions = [];
        if (type === 'view') {
          check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.view);
      }
        if (type === 'create') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.create);
        }
        if (type === 'update') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.update);
        }
        if (type === 'status') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.status);
        }
        if (type === 'delete') {
            check_permissions = localStorgeData.role_permissions.permissions.filter(permission => permission.name === module_name && permission.delete);
        }
    
        if (check_permissions.length > 0) {
            return true;
        } else {
            return false;
        }
    }

    const VIEW_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('id', calendar_id);
        FORM_DATA.append('token', USER_DATA.token);
        const EDIT_ROLE_API_RESPONSE = await EDIT_CALENDAR(FORM_DATA);
        if (EDIT_ROLE_API_RESPONSE?.data?.status) {
            set_title(EDIT_ROLE_API_RESPONSE?.data?.calendar?.title);
            set_calendar_start(EDIT_ROLE_API_RESPONSE?.data?.calendar?.year_start_date);
            set_calendar_end(EDIT_ROLE_API_RESPONSE?.data?.calendar?.year_end_date);
            set_description(EDIT_ROLE_API_RESPONSE?.data?.calendar?.description)

        } else {
            set_errors(EDIT_ROLE_API_RESPONSE.data.errors);
        }
    }
    const STATUS_API = async (id) => {
        set_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('id', id);
        const API_RESPONSE = await STATUS_SEMESTER(FORM_DATA);
        if (API_RESPONSE.data.status) {
            notification.open({
                message: 'Success!!',
                description: 'Semester status Successfully changed.',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            navigate('/edit-calendar/'+calendar_id)
        } else {
            set_errors(API_RESPONSE.data.errors);
            set_loader(false)
        }
    }
    const DELETE_API = async (id) => {
        set_loader(true)
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('id', id);
        const API_RESPONSE = await DELETE_SEMESTER(FORM_DATA);
        if (API_RESPONSE.data.status) {
            notification.open({
                message: 'Success!!',
                description: 'Semester Successfully deleted.',
                icon: <SmileOutlined style={{ color: 'green' }} />,
            });
            navigate('/edit-calendar/'+calendar_id)
        } else {
            set_errors(API_RESPONSE.data.errors);
            set_loader(false)
        }
    }
    const columns = [
        {
            title: '#',
            dataIndex: 'id',
            key: 'id'
        },
        {
            title: 'Semester Title',
            render: (text, record) => {
                return (record.title);
            }
        },
        {
            title: 'Semester Start Date',
            render: (text, record) => {
                return(
                  <>
                  {sisDateFormat(record.start_date)}
                  </>
                )
              }
          
        },
        {
            title: 'Semester End Date',
            render: (text, record) => {
                return(
                  <>
                  {sisDateFormat(record.end_date)}
                  </>
                )
              }
        },
        {
            title: 'Status',
            dataIndex: 'status',
            key: 'status',
            render: (text, record) => {
                if (record.status) {
                    return (
                        <span className="table-status-activate">Active</span>
                    );
                } else {
                    return (
                        <span className="table-status-deactivate">Inactive</span>
                    );
                }
            }
        },
        {
            title: 'Action',
            key: 'Action',
            render: (text, record) => {
                return (
                    <>
                      {(check_permissions('calendars_schedules','create') || check_permissions('calendars_schedules','update') || check_permissions('calendars_schedules','view'))&&
                        <Button type="primary" size="small" onClick={() => navigate('/semester-courses/' + calendar_id+'/'+btoa(record.id))} style={{ marginRight: "5px",background:"#B64141" }}>Schedule</Button>
                      }          
                    </>
                )
            }

        },
    ];
    // DB list
    const LIST_API = async () => {
        const USER_DATA = JSON.parse(localStorage.getItem('sis_user_data'));
        const FORM_DATA = new FormData();
        FORM_DATA.append('token', USER_DATA.token);
        FORM_DATA.append('calendar_id', calendar_id);
        const API_RESPONSE = await SEMESTERS_LIST(FORM_DATA);
        if (API_RESPONSE?.data?.status) {
            set_table_list(API_RESPONSE.data.semesters);
            set_loader(false);
        } else {
            set_loader(false);
        }
    }
    useEffect(() => {
        VIEW_API();
        LIST_API();
    }, [loader]);

   
    return (
        <div>
          <div className='theme-content-head'>
                    <div className='theme-content-left-head'>
                        <h3>Academic Calendar Schedule ({title})</h3>
                    </div>
                    <div className='theme-content-right-head'>

                    </div>
                </div>

                <div className='sis-tab-menu'>
                    {check_permissions('academic_calendars', 'view') &&
                    <span onClick={() =>  navigate('/edit-calendar/'+calendar_id)}>Academic Calendar</span>}
                    {check_permissions('academic_semesters', 'view') && <span  onClick={() =>  navigate('/calendar-semester/'+calendar_id)}>Semesters</span>}
                    {check_permissions('calendars_schedules', 'view') && <span  className='active'>Schedules</span>}
                </div>
               
            {loader ? <SisLoader /> : <>
            <Table columns={columns} dataSource={table_list} />
            </>}
            
        </div>
    );
};


export default CalendarSchedule;