import React, { useEffect, useState } from "react";
import StudentSingleProgramRow from "./studentSingleProgramRow";
import { Button, message, Popconfirm } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { CALENDARS_LIST, PROGRAMS_LIST } from "../../../apis/apis";
import { useParams } from "react-router-dom";
import AddNewProgramModal from "./addNewProgramModal";

const StudentMultipleProgram = ({
  student_program_registration_data,
  set_page_loader,programme_list,entering_year_list,sem_list,page_loader
}) => {
  const { id } = useParams();
 
  const [addNewProgramModal, setAddNewProgramModal] = useState(false);
 


  return (
    <>
     <div className="theme-content-head">
            <div className="theme-content-left-head">
              <h3>Program Registration</h3>
            </div>
            {(student_program_registration_data?.add_program_view || student_program_registration_data?.new_student) &&
            <Popconfirm
              title="Add New Program"
              description="Are you sure you want to add a new program ?"
              placement="leftTop"
              onConfirm={()=>setAddNewProgramModal(true)}
              onCancel={()=>setAddNewProgramModal(false)}
              okText="Yes"
              cancelText="No"
            >
              <div className="theme-content-right-head">
                <Button
                  className="lusisbtn"
                  style={{ marginRight: "5px", width: "200px" }}
                  type="primary"
                  onClick={() => {}}
                >
                  <PlusOutlined />
                  Add New Program
                </Button>
              </div>
            </Popconfirm>
           }
          </div>
      {student_program_registration_data?.programme?.length > 0 ? 
        <>
          {student_program_registration_data?.programme?.map(
            (student_program_registration_list) => (
              <>
              <div style={{ background : !student_program_registration_list?.old_program ? "#ffeeec" : "white",padding:"10px 10px 0px 10px" }}>
                <StudentSingleProgramRow
                  set_page_loader={set_page_loader}
                  student_program_registration_list={
                    student_program_registration_list
                  }
                  programme_list={programme_list}
                  entering_year_list={entering_year_list}
                  sem_list={sem_list}
                  page_loader={page_loader}
                  add_program_view={student_program_registration_data?.add_program_view}
                />
                </div>
                <hr />
                <br />
              </>
            )
          )}
        </>
        :
        <>
        <div className="row">
          <div style={{margin:"0px 0"}}>
          <p style={{textAlign:"center",color:"red",fontSize:"20px"}}>Student not register in any program.</p>
          </div>
        </div>
        </>
      }
       {addNewProgramModal && 
          <AddNewProgramModal 
          addNewProgramModal={addNewProgramModal}
          setAddNewProgramModal={setAddNewProgramModal}
          programme_list={programme_list} 
          entering_year_list={entering_year_list} 
          set_page_loader={set_page_loader}
          page_loader={page_loader}
          />
          }
    </>
  );
};

export default StudentMultipleProgram;
